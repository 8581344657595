import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';

export type FavInfo = {
    chainId: number;
    address: string;
    favTime?: Date;
}

export type ModFavInfoOperation = {
    favInfo: FavInfo;
    isAdd: boolean; // add or remove
}

export interface FavoriteState {
    favToken: Record<string, FavInfo>;
    favPool: Record<string, FavInfo>;
    favLiquidity: Record<string, FavInfo>; // chainId as nft_id, address as pool_address
}

export const favInfoKey = (favInfo: FavInfo) : string => `${favInfo.chainId}-${favInfo.address}`;

export const favorite = createModel<RootModel>()({
    state: {
        favToken: {},
        favPool: {},
        favLiquidity: {},
    } as FavoriteState,
    reducers: {
        modFavToken: (state: FavoriteState, {favInfo, isAdd}: ModFavInfoOperation) => produce(state, draft => {
            const key = favInfoKey(favInfo);
            if (isAdd) {
                draft.favToken[key] = favInfo;
            } else if (key in draft.favToken) {
                delete draft.favToken[key];
            }
        }),
        modFavPool: (state: FavoriteState, {favInfo, isAdd}: ModFavInfoOperation) => produce(state, draft => {
            const key = favInfoKey(favInfo);
            if (isAdd) {
                draft.favPool[key] = favInfo;
            } else if (key in draft.favPool) {
                delete draft.favPool[key];
            }
        }),
        modFavLiquidity: (state: FavoriteState, {favInfo, isAdd}: ModFavInfoOperation) => produce(state, draft => {
            const key = favInfoKey(favInfo);
            if (isAdd) {
                draft.favLiquidity[key] = favInfo;
            } else if (key in draft.favLiquidity) {
                delete draft.favLiquidity[key];
            }
        }),
    },
});
