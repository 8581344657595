import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import chains, { BaseChain, getChain } from '../config/chains';
import { RootDispatch, RootState } from '../state/store';
import { ChainId } from '../types/mod';
import { useRematchDispatch } from './useRematchDispatch';

export const useChain = (): { chainId: number; chain: BaseChain; setChainId: (chainId: number) => void } => {
    const {
        announceBar: { chainId },
    } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const history = useHistory();
    const location = useLocation();

    const chain = useMemo(() => {
        let res = chains.all.find((e) => {
            return e.id === chainId;
        }) as BaseChain;

        if (!res) {
            res = getChain(process.env.REACT_APP_ENV === 'production' ? ChainId.BSC : ChainId.BSCTestnet) as BaseChain;
        }
        return res;
    }, [chainId]);

    return {
        chainId: chain.id,
        chain,
        setChainId: (chainId: number) => {
            if (location.pathname === '/Dashboard') {
                history.push(`/Dashboard?chainId=${chainId}`);
            } else {
                dispatch.announceBar.setChainId(chainId);
            }
        },
    };
};
