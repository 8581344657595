import React from 'react';
import { Stack } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import { PoolFavoriteBlock } from './components/PoolFavoriteBlock';
import { PoolsBlock } from './components/PoolsBlock';

const Pools: React.FC = () => {
    return (
        <PageLayout
            header={<></>}
            body={
                <Stack w="100%">
                    <PoolFavoriteBlock />
                    <PoolsBlock />
                </Stack>
            }
        />
    );
};

export default Pools;
