import { Flex } from '@chakra-ui/react';
import { ReactElement } from 'react';
import useIsMobile from '../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import './AppLayout.css';

export type AppLayoutProps = {
    left: ReactElement;
    center: ReactElement;
    header: ReactElement;
    bottom?: ReactElement;
};

const AppLayout: React.FC<AppLayoutProps> = (props) => {
    const isMobile = useIsMobile();
    return (
        <>
            {props.header}
            <Flex direction="row" minH="100vh" w="100%" className="AppLayout">
                {props.left}
                {props.center}
            </Flex>
            {isMobile && props.bottom}
        </>
    );
};
export default AppLayout;
