import { BoxProps, Heading, HStack, Text, useColorMode, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from '../../components/Card/Card';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import useIsMobile from '../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { ResponseIziSwapTokenSummaryRecord } from '../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { favInfoKey } from '../../state/models/favorite/favorite';
import { RootDispatch, RootState } from '../../state/store';
import { i_text_copy } from '../../style';
import { calRatio, getColorThemeSelector } from '../../utils/funcs';
import { formatNumber } from '../../utils/tokenMath';
import { ChangeValue } from '../../views/components/ChangeValue';
import { FavButton } from '../../views/components/FavButton';
import { TokenIconWithChain } from '../../views/components/TokenIconWithChain';

type MidTokenListProps = {
    tokenSummaryList: ResponseIziSwapTokenSummaryRecord[];
} & BoxProps;

export const MidTokenList: React.FC<MidTokenListProps> = (props) => {
    const { tokenSummaryList, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const isMobile = useIsMobile();
    const history = useHistory();
    const jump = (e: ResponseIziSwapTokenSummaryRecord) => {
        history.push(`/token?chainId=${e.chainId}&tokenAddress=${e.address}`);
    };

    const { favorite } = useSelector((state: RootState) => state);
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);

    const tab = isMobile
        ? [['Tokens', '150px']]
        : [
              ['Tokens', '150px'],
              ['Price', '110px'],
              ['Price(24h)', '110px'],
              ['TVL(24h)', '110px'],
              ['Volume(24h)', '110px'],
          ];

    return (
        <Card variant="base" w="100%" alignItems="left" p="20px" borderRadius="0" boxShadow="none" {...rest}>
            <VStack spacing="15px" alignItems="left">
                <HStack spacing="10px">
                    {tab.map((e: string[], i: number) => {
                        const isMainTh = i === 0;
                        return (
                            <Text
                                key={i}
                                w={e[1]}
                                className={i_text_copy}
                                fontSize="12px"
                                color={colorTheme('gray.400', isMainTh ? 'gray.100' : 'gray.0')}
                                fontWeight={isMainTh ? 'bold' : 'normal'}
                            >
                                {e[0]}
                            </Text>
                        );
                    })}
                </HStack>

                {tokenSummaryList.length > 0 ? (
                    tokenSummaryList.map((item, i) => {
                        const favInfo = {
                            chainId: item.chainId,
                            address: item.address,
                        };
                        return (
                            <HStack key={i} spacing="10px">
                                <HStack
                                    w="150px"
                                    cursor="pointer"
                                    spacing="5px"
                                    onClick={() => {
                                        jump(item);
                                    }}
                                >
                                    <TokenIconWithChain tokenAddr={item.address} chainId={item.chainId} />
                                    <Heading level="5" fontSize="14px">
                                        {item.symbol}
                                    </Heading>
                                    <Heading level="5" color="gray.400" fontSize="14px">
                                        ({item.symbol})
                                    </Heading>
                                </HStack>
                                {!isMobile && (
                                    <HStack w="110px">
                                        <Text className={i_text_copy} fontSize="13px">
                                            {'$ ' + formatNumber(item.price, 2, 2)}
                                        </Text>
                                    </HStack>
                                )}
                                {!isMobile && (
                                    <HStack w="110px">
                                        <ChangeValue value={calRatio(item.price, item.priceDay)} percentage={true} />
                                    </HStack>
                                )}

                                {!isMobile && (
                                    <HStack w="110px">
                                        <Text className={i_text_copy} fontSize="13px">
                                            {'$ ' + formatNumber(item.tvl, 2, 2)}
                                        </Text>
                                    </HStack>
                                )}
                                {!isMobile && (
                                    <HStack w="110px">
                                        <Text className={i_text_copy} fontSize="13px">
                                            {'$ ' + formatNumber(item.volDay, 2, 2)}
                                        </Text>
                                    </HStack>
                                )}

                                <FavButton
                                    w="20px"
                                    h="20px"
                                    variant="base"
                                    isFav={favInfoKey(favInfo) in favorite.favToken}
                                    setIsFav={(isAdd: boolean) =>
                                        dispatch.favorite.modFavToken({
                                            isAdd,
                                            favInfo,
                                        })
                                    }
                                    boxSize="15px"
                                    m="0!important"
                                />
                            </HStack>
                        );
                    })
                ) : (
                    <Text className={i_text_copy} fontSize="15px" py="8px" color={colorTheme('gray.400', 'gray.0')}>
                        No Result
                    </Text>
                )}
            </VStack>
        </Card>
    );
};
