import React from 'react';
import { Stack } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import PageHeader from '../../components/PageHeader/PageHeader';
import { PoolInfoBlock } from './components/PoolInfoBlock';
import { TransactionBlock } from './components/TransactionsBlock';

const PoolDetail: React.FC = () => {
    return (
        <PageLayout
            header={<PageHeader title="" subtitle="" variant="type2" />}
            body={
                <Stack w="100%">
                    <PoolInfoBlock mt="0px" />
                    <TransactionBlock />
                </Stack>
            }
        />
    );
};

export default PoolDetail;
