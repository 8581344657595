import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
import { TransRecordTypeEnum } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';

export interface PoolSetting {
    page: number;
    pageSize: number;
    orderBy?: string;
}

export interface TokenSetting {
    page: number;
    pageSize: number;
    orderBy?: string;
}

export interface TransactionsSetting {
    page: number;
    pageSize: number;
    filterType?: TransRecordTypeEnum;
}

export interface TransactionsData {
    totalPage?: number;
}

export interface DashboardState {
    poolSetting: PoolSetting;
    tokenSetting: TokenSetting;
    transSetting: TransactionsSetting;
    transData: TransactionsData;
}


export const dashboard = createModel<RootModel>()({
    state: {
        poolSetting: {
            page: 1,
            pageSize: 10,
            orderBy:'-vol_day',
        },
        tokenSetting: {
            page: 1,
            pageSize: 10,
            orderBy:'-vol_day',
        },
        transSetting: {
            page: 1,
            pageSize: 10,
        },
        transData: {
            totalPage: 1,
        },
    } as DashboardState,
    reducers: {
        setPoolSetting: (state: DashboardState, newPoolDisplay: PoolSetting) => produce(state, draft => {
            draft.poolSetting = { ...draft.poolSetting, ...newPoolDisplay };
        }),
        setTokenSetting: (state: DashboardState, newTokenDisplay: TokenSetting) => produce(state, draft => {
            draft.tokenSetting = { ...draft.tokenSetting, ...newTokenDisplay };
        }),
        setTransSetting: (state: DashboardState, newTransDisplay: TransactionsSetting) => produce(state, draft => {
            draft.transSetting = { ...draft.transSetting, ...newTransDisplay };
        }),
        setTransDataTotalPage: (state: DashboardState, total: number) => produce(state, draft => {
            draft.transData.totalPage = total;
        }),
    },
});
