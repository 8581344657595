import React from 'react';
import { Stack } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import { SearchBlock } from './components/SearchBlock';
import { TransactionBlock } from './components/TransactionBlock';

const Transaction: React.FC = () => {
    return (
        <PageLayout
            header={<></>}
            body={
                <Stack w="100%">
                    <SearchBlock />
                    <TransactionBlock />
                </Stack>
            }
        />
    );
};

export default Transaction;
