import { Box, Flex, Stack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { Heading, Text } from '../Typography/Typography';
import BackButton from '../Buttons/BackButton/BackButton';

export type PageHeaderProps = {
    title: string | ReactElement;
    subtitle: string;
    element?: ReactElement;
    variant?: string;
};

const PageHeader: React.FC<PageHeaderProps> = (props) => {
    return !props.variant ? (
        <Flex p="60px 0 24px" direction="row" w="100%">
            <Stack flexGrow={1}>
                {typeof props.title === 'string' ? <Heading level="3">{props.title}</Heading> : props.title}
                <Text variant="display">{props.subtitle}</Text>
            </Stack>
            {props.element && <Box flexShrink={0}>{props.element}</Box>}
        </Flex>
    ) : (
        <Flex p={{ base: '20px 0 15px', sm: '60px 0 24px' }} direction="row" w="100%" alignItems="center">
            <BackButton flex={20} />
            <Stack flex={80} alignItems="center">
                {typeof props.title === 'string' ? <Heading level="3">{props.title}</Heading> : props.title}
                <Text variant="display">{props.subtitle}</Text>
            </Stack>
        </Flex>
    );
};

export default PageHeader;
