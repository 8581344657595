import React from 'react';
import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs, useColorMode } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import PageHeader from '../../components/PageHeader/PageHeader';
import { PoolsBlock } from './components/PoolsBlock';
import { TokenInfoBlock } from './components/TokenInfoBlock';
import { TransactionBlock } from './components/TransactionsBlock';
import { getColorThemeSelector } from '../../utils/funcs';
import useIsMobile from '../../hooks/useIsMobile';

const TokenDetail: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    return (
        <PageLayout
            header={<PageHeader title="" subtitle="" variant="type2" />}
            body={
                isMobile ? (
                    <Stack w="100%" pb={{ base: '120px', sm: '60px' }}>
                        <TokenInfoBlock mt="0px" />
                        <Tabs variant="unstyled" color="gray.500" mt="26px !important">
                            <TabList fontFamily="Montserrat-Medium">
                                <Tab
                                    w="80px"
                                    px="0px"
                                    pr="10px"
                                    whiteSpace="nowrap"
                                    fontSize="12px !important"
                                    _selected={{ color: colorTheme('#1B1C22', '#E3E3E4'), fontSize: '14px !important', fontWeight: 600 }}
                                >
                                    {'Pools'}
                                </Tab>

                                <Tab
                                    w="100px"
                                    px="0px"
                                    pr="10px"
                                    whiteSpace="nowrap"
                                    fontSize="12px !important"
                                    _selected={{ color: colorTheme('#1B1C22', '#E3E3E4'), fontSize: '14px !important', fontWeight: 600 }}
                                >
                                    {' Transactions'}
                                </Tab>
                            </TabList>

                            <TabPanels mb="80px">
                                <TabPanel p="0px">
                                    <PoolsBlock />
                                </TabPanel>

                                <TabPanel p="0px">
                                    <TransactionBlock />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Stack>
                ) : (
                    <Stack w="100%" pb={{ base: '120px', sm: '60px' }}>
                        <TokenInfoBlock mt="0px" />
                        <PoolsBlock />
                        <TransactionBlock />
                    </Stack>
                )
            }
        />
    );
};

export default TokenDetail;
