import { BoxProps, VStack, Text, useColorMode, HStack, Icon, Divider, Stack, Flex, Image } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { calRatio, getColorThemeSelector } from '../../../utils/funcs';
import { Heading } from '../../../components/Typography/Typography';
import { i_text_copy_bold, i_text_d, i_text_copy } from '../../../style';
import CustomButton from '../../../components/Buttons/CustomButton/CustomButton';
import { BiLinkExternal } from 'react-icons/bi';
import { FavButton } from '../../components/FavButton';
import { TokenIconWithChain } from '../../components/TokenIconWithChain';
import { TVLChart } from '../../components/TVLChart';
import { VolumeChart } from '../../components/VolumeChart';
import { TimeSeriesChart } from '../../components/LineChart';
import { ChangeValue } from '../../components/ChangeValue';
import { formatNumber } from '../../../utils/tokenMath';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { isNumeric } from '../../../utils/valid';
import {
    memGetIziSwapMetaRecordWithDefault,
    MetaRecordTypeEnum,
    ResponseIziSwapTokenRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { OverviewInfo, TimeNumberSeries } from '../../components/types';
import { viewAddress, tradeTokenAddress } from '../../../utils/blockchainExplorerUtils';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { RootDispatch, RootState } from '../../../state/store';
import { favInfoKey } from '../../../state/models/favorite/favorite';
import {
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
    ResponseIziSwapTokenSummaryRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import {
    getIziSwapHourRecord,
    IzumiSwapHourRecordTypeEnum,
    ResponseIziSwapTokenHourRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapHour';
import { TOKEN_DETAIL_INFO } from '../../../config/bizConfig';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { getTokenInfo, mediaProps, mediaTitleProps } from '../../../net/iZUMi-endpoints/src/restful/izumiTokenInfo';
import Card from '../../../components/Card/Card';
import CandleChart from '../../components/CandleChart';
import { CandlestickData } from 'lightweight-charts';
import React from 'react';

type InfoBlockProps = {
    variant?: any;
} & BoxProps;

export const TokenInfoBlock: React.FC<InfoBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const themeColor = colorTheme('#ffffff', '#232433');

    const isMobile = useIsMobile();
    const { favorite } = useSelector((state: RootState) => state);
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);

    const [chart, setChart] = useState('TVL');
    const [candlestickData, setCandlestickData] = useState([] as CandlestickData[]);

    const [latestTokenInfo, setLatestTokenInfo] = useState<ResponseIziSwapTokenSummaryRecord>({} as ResponseIziSwapTokenSummaryRecord);
    const [lastDayTokenInfo, setLastDayTokenInfo] = useState<ResponseIziSwapTokenSummaryRecord>({} as ResponseIziSwapTokenSummaryRecord);
    const [tvlHistory, setTvlHistory] = useState<TimeNumberSeries>([]);
    const [volHistory, setVolHistory] = useState<TimeNumberSeries>([]);
    const [tokenRecord, setTokenRecord] = useState<Partial<ResponseIziSwapTokenRecord>>({});

    const [tokenInfo, setTokenInfo] = useState('');
    const [tokenWebsite, setTokenWebsite] = useState('');
    const [media, setMedia] = useState([] as mediaProps[]);

    const searchParams = useSearchParams();
    const tokenAddress = searchParams.get('tokenAddress') || undefined;
    const chainId = isNumeric(searchParams.get('chainId')) ? Number(searchParams.get('chainId')) : ChainId.BSC;

    const favInfo = { chainId: chainId, address: tokenAddress ?? '' };

    useEffect(() => {
        getIziSwapGenericSummaryRecord<ResponseIziSwapTokenSummaryRecord[]>({
            chain_id: chainId,
            contract_addr: tokenAddress,
            type: SummaryRecordTypeEnum.IZI_POOL_TOKEN_LATEST,
            page_size: TOKEN_DETAIL_INFO.MAX_CHART_DATA,
        }).then((r) => {
            const latest = r.data.is_success ? r.data.data : [];
            setLatestTokenInfo(latest?.[0] ?? {});
        });

        getIziSwapGenericSummaryRecord<ResponseIziSwapTokenSummaryRecord[]>({
            chain_id: chainId,
            contract_addr: tokenAddress,
            type: SummaryRecordTypeEnum.IZI_POOL_TOKEN_DAY_ARCHIVE,
            order_by: '-time',
            page_size: TOKEN_DETAIL_INFO.MAX_CHART_DATA,
        }).then((r) => {
            const tokenHistory = r.data.is_success ? r.data.data : [];
            setLastDayTokenInfo(tokenHistory?.[0] ?? {});

            setTvlHistory(tokenHistory.map((r) => [r.timestamp * 1000, r.tvl]).sort((a, b) => a[0] - b[0]));
            setVolHistory(tokenHistory.map((r) => [r.timestamp * 1000, r.volDay]).sort((a, b) => a[0] - b[0]));
        });

        getIziSwapHourRecord<ResponseIziSwapTokenHourRecord[]>({
            chain_id: chainId,
            contract_addr: tokenAddress,
            type: IzumiSwapHourRecordTypeEnum.IZI_SWAP_TOKEN_HOUR_ARCHIVE,
            order_by: '-time',
            page_size: TOKEN_DETAIL_INFO.MAX_CHART_DATA,
        }).then((r) => {
            const tokenHistory = r.data.is_success ? r.data.data : [];

            const tempData = tokenHistory
                .map((item) => {
                    return {
                        time: item.timestamp,
                        open: item.open,
                        close: item.close,
                        high: item.high,
                        low: item.low,
                    } as CandlestickData;
                })
                .sort((a, b) => Number(a.time) - Number(b.time));
            setCandlestickData(tempData);
        });

        memGetIziSwapMetaRecordWithDefault(MetaRecordTypeEnum.ERC20_TOKEN, chainId, tokenAddress).then((r) => setTokenRecord(r));

        tokenAddress &&
            getTokenInfo({
                address: tokenAddress,
                chainId: chainId,
            }).then((r) => {
                if (String(r.data.data) === 'token not exist') {
                    return;
                }
                setTokenInfo(r.data.data.info);
                setTokenWebsite(r.data.data.link);
                setMedia(r.data.data.media);
            });
    }, [chainId, tokenAddress]);

    const overviewInfo: OverviewInfo = useMemo(() => {
        return {
            tvlLastedDay: latestTokenInfo?.tvl ?? 0,
            volLastedDay: latestTokenInfo?.volDay ?? 0,
            feesLastedDay: latestTokenInfo?.feesDay ?? 0,
            volLastedWeek: latestTokenInfo?.volWeek ?? 0,
            feesLastedWeek: latestTokenInfo?.feesWeek ?? 0,
            tvlDayOnDayRatio: calRatio(latestTokenInfo?.tvl ?? 0, lastDayTokenInfo.tvl ?? 0),
            volDayOnDayRatio: calRatio(latestTokenInfo?.volDay ?? 0, lastDayTokenInfo.volDay ?? 0),
            feesDayOnDayRatio: calRatio(latestTokenInfo?.feesDay ?? 0, lastDayTokenInfo.feesDay ?? 0),
            volWeekOnWeekRatio: calRatio(latestTokenInfo?.volWeek ?? 0, lastDayTokenInfo.volWeek ?? 0),
            feesWeekOnWeekRatio: calRatio(latestTokenInfo?.feesWeek ?? 0, lastDayTokenInfo.feesWeek ?? 0),
        };
    }, [latestTokenInfo, lastDayTokenInfo]);

    const entry = {
        token: tokenRecord.symbol as TokenSymbol,
        tokenAddress: tokenRecord.address as TokenSymbol,
        chainId: chainId,
        name: tokenRecord.name,
    };

    const dataBlock = (name: string, value: number, delta?: number) => {
        return isMobile ? (
            <VStack w="100%" alignItems="left">
                <HStack w="100%" mt="8px !important" justifyContent="space-between">
                    <Text className={i_text_copy} fontSize="16px" color={colorTheme('gray.400', 'gray.400')}>
                        {name}
                    </Text>
                    <HStack>
                        <Text className={i_text_copy} fontSize="16px" color={colorTheme('gray.800', 'gray.0')}>
                            $ {formatNumber(value, 2, 2, 1)}
                        </Text>

                        <ChangeValue value={delta} bracket={true} percentage={true} fontSize="16px" />
                    </HStack>
                </HStack>
            </VStack>
        ) : (
            <VStack alignItems="left">
                <Text className={i_text_copy} fontSize="12px" color={colorTheme('gray.400', 'gray.400')}>
                    {name}
                </Text>
                <HStack mt="8px !important">
                    <Text className={i_text_copy} fontSize="18px" color={colorTheme('gray.800', 'gray.0')}>
                        $ {formatNumber(value, 2, 2, 1)}
                    </Text>

                    <ChangeValue value={delta} bracket={false} percentage={true} />
                </HStack>
            </VStack>
        );
    };

    return (
        <VStack w={{ base: '100%', sm: '1150px' }} alignItems="left" mt="60px !important" spacing="20px" {...rest}>
            {isMobile ? (
                <Card p="18px">
                    <Stack w="100%" spacing="15px" justifyContent="space-between">
                        <HStack spacing="15px">
                            <TokenIconWithChain
                                tokenAddr={entry.tokenAddress as string}
                                chainId={entry.chainId}
                                size="lg"
                                mt="8px !important"
                            />

                            <VStack spacing="4px" alignItems="left">
                                <HStack>
                                    <Heading level="5" fontSize="16px" color={colorTheme('gray.400', 'gray.400')}>
                                        {entry.name}
                                    </Heading>
                                    <Text color={colorTheme('gray.400', 'gray.400')} className={i_text_d} fontSize="16px">
                                        ({entry.token})
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text color={colorTheme('gray.800', 'gray.0')} className={i_text_d} fontSize="18px" fontWeight="bold">
                                        ${latestTokenInfo?.price ? formatNumber(latestTokenInfo?.price, 4, 6, 0) : '-'}
                                    </Text>

                                    <ChangeValue
                                        value={calRatio(latestTokenInfo?.price ?? 0, latestTokenInfo?.priceDay ?? 0)}
                                        bracket={false}
                                        percentage={true}
                                        fontSize="14px"
                                    />
                                </HStack>
                            </VStack>
                            <FavButton
                                w="38px"
                                h="28px"
                                ml="auto !important"
                                isFav={favInfoKey(favInfo) in favorite.favToken}
                                setIsFav={(isAdd: boolean) => dispatch.favorite.modFavToken({ isAdd, favInfo })}
                            />
                        </HStack>
                        <HStack alignItems={'center'} spacing="8px">
                            <CustomButton
                                variant="purple"
                                w="154px"
                                h="40px"
                                bg={colorTheme('#EDEAFC', '#332E4E')}
                                border="unset"
                                text={
                                    <HStack w="100%" position="relative">
                                        <HStack mx="auto !important">
                                            <Text className={i_text_copy_bold} color={colorTheme('#9488AA', '#AE9FCB')}>
                                                View Contract
                                            </Text>
                                            <Image
                                                boxSize="12px"
                                                src={colorTheme(
                                                    '/assets/tokenInfo/grayExternalLink.svg',
                                                    '/assets/tokenInfo/darkGrayExternal.svg'
                                                )}
                                            ></Image>
                                        </HStack>
                                    </HStack>
                                }
                                onClick={() => viewAddress(chainId, tokenAddress)}
                            />
                            <CustomButton
                                variant="purple"
                                w="137px"
                                h="40px"
                                bg={colorTheme('#E3EDFF', '#213254')}
                                border="unset"
                                text={
                                    <HStack w="100%" position="relative">
                                        <HStack mx="auto !important">
                                            <Text className={i_text_copy_bold} color={colorTheme('#416AFC', '#8B9ACC')}>
                                                Swap
                                            </Text>
                                            <Image
                                                boxSize="11px"
                                                src={colorTheme(
                                                    '/assets/tokenInfo/blueExternalLink.svg',
                                                    '/assets/tokenInfo/darkBlueExternal.svg'
                                                )}
                                            ></Image>
                                        </HStack>
                                    </HStack>
                                }
                                onClick={() => tradeTokenAddress(chainId, tokenAddress ?? 'GasToken')}
                            />
                        </HStack>
                    </Stack>
                </Card>
            ) : (
                <Stack w="100%" direction={{ base: 'column', sm: 'row' }} mt="2px !important" spacing="30px" justifyContent="space-between">
                    <HStack spacing="20px">
                        <TokenIconWithChain
                            tokenAddr={entry.tokenAddress as string}
                            chainId={entry.chainId}
                            size="lg"
                            mt="8px !important"
                        />

                        <VStack spacing="4px" alignItems="left">
                            <HStack>
                                <Heading level="5" fontSize="18px" color={colorTheme('gray.400', 'gray.400')}>
                                    {entry.name}
                                </Heading>
                                <Text color={colorTheme('gray.400', 'gray.400')} className={i_text_d} fontSize="18px">
                                    ({entry.token})
                                </Text>
                            </HStack>
                            <HStack>
                                <Text color={colorTheme('gray.800', 'gray.0')} className={i_text_d} fontSize="28px" fontWeight="bold">
                                    ${latestTokenInfo?.price ? formatNumber(latestTokenInfo?.price, 4, 6, 0) : '-'}
                                </Text>

                                <ChangeValue
                                    value={calRatio(latestTokenInfo?.price ?? 0, latestTokenInfo?.priceDay ?? 0)}
                                    bracket={false}
                                    percentage={true}
                                    fontSize="15px"
                                    mt="6px !important"
                                />
                            </HStack>
                        </VStack>
                    </HStack>
                    <Stack direction={{ base: 'column', sm: 'row' }} alignItems={'center'} spacing="20px">
                        <CustomButton
                            variant="purple"
                            w="200px"
                            h="40px"
                            text={
                                <HStack w="100%" position="relative">
                                    <HStack mx="auto !important">
                                        <Text className={i_text_copy_bold} fontSize="14px">
                                            View Contract
                                        </Text>
                                        <Icon as={BiLinkExternal} boxSize="16px"></Icon>
                                    </HStack>
                                </HStack>
                            }
                            onClick={() => viewAddress(chainId, tokenAddress)}
                        />
                        <CustomButton
                            variant="purple"
                            w={{ base: '200px', sm: '100px' }}
                            h="40px"
                            text={
                                <HStack w="100%" position="relative">
                                    <HStack mx="auto !important">
                                        <Text className={i_text_copy_bold} fontSize="14px">
                                            Swap
                                        </Text>
                                        <Icon as={BiLinkExternal} boxSize="16px"></Icon>
                                    </HStack>
                                </HStack>
                            }
                            onClick={() => tradeTokenAddress(chainId, tokenAddress ?? 'GasToken')}
                        />
                        <FavButton
                            isFav={favInfoKey(favInfo) in favorite.favToken}
                            setIsFav={(isAdd: boolean) => dispatch.favorite.modFavToken({ isAdd, favInfo })}
                        />
                    </Stack>
                </Stack>
            )}
            <Card w="100%" h={{ base: 'unset', sm: '358px' }} position="relative">
                <HStack
                    right="20px"
                    top={{ base: '13px', sm: '20px' }}
                    spacing={{ base: '7px', sm: '20px' }}
                    zIndex={3}
                    position="absolute"
                >
                    {['TVL', 'Volume', 'Price'].map((e: string, i: number) => {
                        return isMobile ? (
                            <CustomButton
                                variant={chart === e ? 'purple' : 'outlinePurple'}
                                bg={chart === e ? 'linear-gradient(300.66deg, rgba(242, 244, 255, 0) 1.76%, #F2F4FF 85.3%)' : 'inherit'}
                                color={chart === e ? '#001AFF' : 'gray.400'}
                                _hover={{}}
                                _focus={{}}
                                border="unset"
                                onClick={() => {
                                    setChart(e);
                                }}
                                borderRadius="3px"
                                key={i}
                                ml={i === 0 ? '0px' : 'unset'}
                                text={e}
                                h="30px"
                                w="unset"
                                px="10px"
                                fontSize="12px"
                            />
                        ) : (
                            <CustomButton
                                variant={chart === e ? 'purple' : 'outlinePurple'}
                                onClick={() => {
                                    setChart(e);
                                }}
                                borderRadius="3px"
                                key={i}
                                ml={i === 0 ? '0px' : 'unset'}
                                text={e}
                                h="30px"
                                w="unset"
                                px="10px"
                                fontSize="12px"
                            />
                        );
                    })}
                </HStack>
                {isMobile && <Divider border="0px solid #F0F0F0" top="50px" zIndex={2} position="absolute"></Divider>}

                {chart === 'TVL' && (
                    <TVLChart
                        w="100%"
                        h="350px"
                        height="330px"
                        mt={{ base: '60px', sm: '0px' }}
                        variant="none"
                        series={[
                            {
                                name: 'TVL',
                                data: tvlHistory,
                            },
                        ]}
                    />
                )}
                {chart === 'Volume' && (
                    <VolumeChart
                        w="100%"
                        h="350px"
                        height="330px"
                        mt={{ base: '60px', sm: '0px' }}
                        variant="none"
                        series={[
                            {
                                name: 'Volume',
                                data: volHistory,
                            },
                        ]}
                    />
                )}
                {chart === 'Price' && (
                    <VStack w="100%" h="350px" pt={{ base: '60px', sm: '0px' }} justifyContent="end">
                        <CandleChart themeColor={themeColor} data={candlestickData}></CandleChart>
                    </VStack>
                )}
            </Card>

            <Card w="100%" h={{ base: 'unset', sm: '85px' }} py={{ base: '23px', sm: '20px' }} px={{ base: '30px', sm: '60px' }}>
                <Stack w="100%" direction={{ base: 'column', sm: 'row' }} justify="space-between" spacing={{ base: '20px', sm: '8px' }}>
                    {dataBlock('TVL', overviewInfo.tvlLastedDay, overviewInfo.tvlDayOnDayRatio)}
                    {!isMobile && <Divider h="50px" orientation="vertical" />}
                    {dataBlock('Volume(24h)', overviewInfo.volLastedDay, overviewInfo.volDayOnDayRatio)}
                    {!isMobile && <Divider h="50px" orientation="vertical" />}
                    {dataBlock('Volume(7d)', overviewInfo.volLastedWeek as number, overviewInfo.volWeekOnWeekRatio)}
                    {!isMobile && <Divider h="50px" orientation="vertical" />}
                    {dataBlock('Fees(24h)', overviewInfo.feesLastedDay as number, overviewInfo.feesDayOnDayRatio)}
                    {!isMobile && <Divider h="50px" orientation="vertical" ml="50px !important" />}
                    {dataBlock('Fees(7d)', overviewInfo.feesLastedWeek as number, overviewInfo.feesWeekOnWeekRatio)}
                </Stack>
            </Card>

            {tokenInfo && (
                <Card w="100%" h={{ base: 'unset', sm: '196px' }} py="20px" px={{ base: '24px', sm: '45px' }} justifyContent="center">
                    <Flex w="100%" h={{ base: 'unset', sm: '118px' }} direction={{ base: 'column', sm: 'row' }}>
                        <Stack w={{ base: '100%', sm: '574px' }}>
                            {!isMobile && (
                                <Text className={i_text_copy} color="gray.400">
                                    Info
                                </Text>
                            )}
                            <Text
                                className={i_text_copy}
                                w={{ base: '100%', sm: '529px' }}
                                h={{ base: 'unset', sm: '28px' }}
                                color={colorTheme('#5F647C', '#A2A9C7')}
                                isTruncated={true}
                            >
                                {tokenInfo}
                            </Text>
                        </Stack>
                        <Divider orientation="vertical"></Divider>
                        {isMobile && <Divider my="18px" borderColor="#C6C7CF"></Divider>}
                        <Stack ml={{ base: '0px', sm: '38px' }} justifyContent="space-between">
                            <Stack>
                                <Text className={i_text_copy} color="gray.400">
                                    Website
                                </Text>
                                <Text
                                    className={i_text_copy}
                                    color={colorTheme('#5F647C', '#A2A9C7')}
                                    cursor="pointer"
                                    onClick={() => {
                                        window.open(tokenWebsite);
                                    }}
                                >
                                    {tokenWebsite}
                                </Text>
                            </Stack>
                            <Stack>
                                <Text className={i_text_copy} color="gray.400" pt="12px">
                                    Media
                                </Text>
                                <HStack spacing={{ base: '20px', sm: '26px' }} pt="2px">
                                    {media.map((item, index) => {
                                        return mediaTitleProps.includes(item.title) ? (
                                            <Image
                                                key={index}
                                                boxSize="30px"
                                                src={colorTheme(
                                                    '/assets/tokenInfo/' + item.title + '.svg',
                                                    '/assets/tokenInfo/dark' + item.title + '.svg'
                                                )}
                                                fallbackSrc={'/assets/tokenInfo/' + item.title + '.svg'}
                                                cursor="pointer"
                                                onClick={() => {
                                                    window.open(item.link);
                                                }}
                                            ></Image>
                                        ) : (
                                            <Text
                                                key={index}
                                                className={i_text_copy}
                                                color="#5F647C"
                                                cursor="pointer"
                                                onClick={() => {
                                                    window.open(item.link);
                                                }}
                                            >
                                                {item.title}
                                            </Text>
                                        );
                                    })}
                                </HStack>
                            </Stack>
                        </Stack>
                    </Flex>
                </Card>
            )}
        </VStack>
    );
};
