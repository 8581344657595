import { BoxProps, VStack, Text, useColorMode, HStack, Divider, Flex, Image, Icon, Stack } from '@chakra-ui/react';
import moment from 'moment';
import { ReactElement } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Pagination } from '../../../components/Pagination/Pagination';
import { useChain } from '../../../hooks/useChain';
import { useTokenList } from '../../../hooks/useTokenConfig';
import { ResponseIziTransRecord, TransRecordTypeEnum } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { DEFAULT_TIME_FORMAT, TokenSymbol } from '../../../types/mod';
import { viewTrans } from '../../../utils/blockchainExplorerUtils';
import { getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { FeeRate } from '../../components/FeeRate';
import { TokenIcons } from '../../components/TokenIcons';
import { useHistory } from 'react-router-dom';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { MultiPageProps } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';

type PoolListProps = MultiPageProps<ResponseIziTransRecord> & BoxProps;

export const LiquidityList: React.FC<PoolListProps> = (props) => {
    const { setting, pageData, setCurrentPage, setPageSize, children, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const history = useHistory();

    const jumpPoolDetail = (e: ResponseIziTransRecord) => {
        history.push(`/pool?chainId=${e.chainId}&poolAddress=${e.address}`);
    };

    const trStyle = {
        className: { i_text_copy },
        fontSize: '12px',
        color: colorTheme('gray.400', 'gray.0'),
    } as unknown as BoxProps;

    const tabs = [
        {
            width: '226px',
            title: 'Pool/fees',
        },
        {
            width: '112px',
            title: 'Type',
        },
        {
            width: '330px',
            title: 'Transaction',
        },

        {
            width: '200px',
            title: 'Order Date',
        },
    ];

    const { getTokenInfo } = useTokenList();
    const { chainId } = useChain();
    const getActionCell = (r: ResponseIziTransRecord): ReactElement => {
        switch (r.type) {
            case TransRecordTypeEnum.IZI_SWAP_INC_LIQ:
            case TransRecordTypeEnum.IZI_SWAP_DEC_LIQ:
                return (
                    <Flex direction={{ base: 'column', sm: 'row' }}>
                        <Flex>
                            <Image
                                boxSize="15px"
                                src={
                                    process.env.PUBLIC_URL +
                                    (getTokenInfo(r.chainId, r.tokenX_address)?.icon ?? '/assets/tokens/default.svg')
                                }
                            ></Image>
                            <Text ml="6px" w="120px" className={i_text_copy}>
                                {formatNumber(r.amountX as any, 2, 5, 2)} {r.tokenX}
                            </Text>
                        </Flex>
                        <Text mx="6px" className={i_text_copy}>
                            +
                        </Text>
                        <Flex>
                            <Image
                                boxSize="15px"
                                src={
                                    process.env.PUBLIC_URL +
                                    (getTokenInfo(r.chainId, r.tokenY_address)?.icon ?? '/assets/tokens/default.svg')
                                }
                                ml={{ base: '0px !important', sm: '20px !important' }}
                            ></Image>
                            <Text ml="6px" className={i_text_copy}>
                                {' '}
                                {formatNumber(r.amountY as any, 2, 5, 2)}{' '}
                            </Text>
                            <Text ml="3px" className={i_text_copy}>
                                {' '}
                                {r.tokenY}
                            </Text>
                        </Flex>
                    </Flex>
                );

            default:
                return <></>;
        }
    };

    return (
        <Flex direction="column" variant="base" w="100%" alignItems="left" p="30px 20px 30px 20px" mt="10px" {...rest}>
            <VStack alignItems="left">
                {!isMobile && (
                    <HStack ml="20px">
                        {tabs.map((item, i: any) => {
                            return (
                                <Text key={i} w={item.width} {...trStyle}>
                                    {item.title}
                                </Text>
                            );
                        })}
                    </HStack>
                )}
                <Divider />
                {pageData.loading ? (
                    <Flex direction="column" mt="70px !important" alignItems="center">
                        <img width="20px" height="20px" src="/assets/Transaction/loading.gif" />
                        <Text className={i_text_copy} mt="16px">
                            Searching
                        </Text>
                    </Flex>
                ) : (
                    pageData.data.map((item: any, i: number) => {
                        return (
                            <Stack direction={{ base: 'column', sm: 'row' }} key={i}>
                                <HStack
                                    w={tabs[0].width}
                                    mt="2px !important"
                                    ml={{ base: '0px !important', sm: '20px !important' }}
                                    cursor="pointer"
                                    onClick={() => jumpPoolDetail(item)}
                                >
                                    <TokenIcons
                                        tokenAddrA={item.tokenX_address}
                                        tokenAddrB={item.tokenY_address}
                                        initialToggle={false}
                                        chainId={item.chainId}
                                        showChainIcon={true}
                                    />
                                    <FeeRate
                                        tokenA={item.tokenX as TokenSymbol}
                                        tokenB={item.tokenY as TokenSymbol}
                                        feeTier={item.fee}
                                        initialToggle={false}
                                    />
                                </HStack>

                                <HStack w={{ base: 'unset', sm: tabs[1].width }}>
                                    {isMobile && (
                                        <Text w="80px" {...trStyle}>
                                            {tabs[1].title}
                                        </Text>
                                    )}

                                    <Flex h="24px" py="6px" px="10px" bg={colorTheme('secondary.50', '#2E2650')} borderRadius="3px">
                                        <Text className={i_text_copy}>
                                            {item.type === TransRecordTypeEnum.IZI_SWAP_INC_LIQ && 'Add'}
                                            {item.type === TransRecordTypeEnum.IZI_SWAP_DEC_LIQ && 'Dec'}
                                        </Text>
                                    </Flex>
                                </HStack>

                                <HStack w={{ base: 'unset', sm: tabs[2].width }}>
                                    {isMobile && (
                                        <Text w="80px" {...trStyle}>
                                            {tabs[2].title}
                                        </Text>
                                    )}

                                    <Flex color={colorTheme('#7F4AFE', '#B08EFF')} className={i_text_copy_bold} fontSize="13px">
                                        {getActionCell(item)}
                                    </Flex>
                                </HStack>

                                <HStack w={{ base: 'unset', sm: tabs[3].width }}>
                                    {isMobile && (
                                        <Text w="80px" {...trStyle}>
                                            {tabs[3].title}
                                        </Text>
                                    )}

                                    <Text className={i_text_copy} fontSize="13px">
                                        {moment(Number(item.timestamp) * 1000).format(DEFAULT_TIME_FORMAT)}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Flex>
                                        <Text className={i_text_copy_bold} fontSize="13px" minW="100px">
                                            {truncateWalletAddress(item.account, 6, 3)}
                                        </Text>
                                        <Icon
                                            as={FiExternalLink}
                                            ml="10px"
                                            onClick={() => viewTrans(item.chainId, item.txHash)}
                                            boxSize="16px"
                                            cursor="pointer"
                                        />
                                    </Flex>
                                </HStack>
                            </Stack>
                        );
                    })
                )}

                <Pagination
                    totalPage={pageData.totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="50px !important"
                />
            </VStack>
        </Flex>
    );
};
