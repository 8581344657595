import { BoxProps, HStack, Text, useColorMode, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from '../Card/Card';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import { favInfoKey } from '../../state/models/favorite/favorite';
import { RootDispatch, RootState } from '../../state/store';
import { i_text_copy } from '../../style';
import { TokenSymbol } from '../../types/mod';
import { getColorThemeSelector } from '../../utils/funcs';
import { formatNumber } from '../../utils/tokenMath';
import { FavButton } from '../../views/components/FavButton';
import { TokenIcons } from '../../views/components/TokenIcons';
import { FeeRate } from '../../views/components/FeeRate';
import { ResponseIziSwapPoolSummaryRecord } from '../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import useIsMobile from '../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

type MidPoolListProps = {
    poolSummaryList: ResponseIziSwapPoolSummaryRecord[];
} & BoxProps;

export const MidPoolList: React.FC<MidPoolListProps> = (props) => {
    const { poolSummaryList, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const isMobile = useIsMobile();
    const history = useHistory();
    const jump = (e: ResponseIziSwapPoolSummaryRecord) => {
        history.push(`/pool?chainId=${e.chainId}&poolAddress=${e.address}`);
    };

    const { favorite } = useSelector((state: RootState) => state);
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);

    const tab = isMobile
        ? [['Pools', '270px']]
        : [
              ['Pools', '270px'],
              ['TVL(24h)', '110px'],
              ['Volume(24h)', '110px'],
              ['Volume(7d)', '110px'],
          ];
    return (
        <Card variant="base" w="100%" alignItems="left" p="20px" borderRadius="0" boxShadow="none" {...rest}>
            <VStack spacing="15px" alignItems="left">
                <HStack spacing="10px">
                    {tab.map((e: string[], i: number) => {
                        const isMainTh = i === 0;
                        return (
                            <Text
                                key={i}
                                w={e[1]}
                                className={i_text_copy}
                                fontSize="12px"
                                color={colorTheme('gray.400', isMainTh ? 'gray.100' : 'gray.0')}
                                fontWeight={isMainTh ? 'bold' : 'normal'}
                            >
                                {e[0]}
                            </Text>
                        );
                    })}
                </HStack>

                {poolSummaryList.length > 0 ? (
                    poolSummaryList.map((item, i) => {
                        const favInfo = {
                            chainId: item.chainId,
                            address: item.address,
                        };
                        return (
                            <HStack key={i} spacing="10px">
                                <HStack
                                    w="270px"
                                    cursor="pointer"
                                    spacing="5px"
                                    onClick={() => {
                                        jump(item);
                                    }}
                                >
                                    <TokenIcons
                                        tokenAddrA={item.tokenX_address}
                                        tokenAddrB={item.tokenY_address}
                                        initialToggle={false}
                                        chainId={item.chainId}
                                        showChainIcon={true}
                                    />
                                    <FeeRate
                                        tokenA={item.tokenX as TokenSymbol}
                                        tokenB={item.tokenY as TokenSymbol}
                                        feeTier={item.fee}
                                        initialToggle={false}
                                    />
                                </HStack>
                                {!isMobile && (
                                    <HStack w="110px">
                                        <Text className={i_text_copy} fontSize="13px">
                                            {'$ ' + formatNumber(item.tvl, 2, 2)}
                                        </Text>
                                    </HStack>
                                )}
                                {!isMobile && (
                                    <HStack w="110px">
                                        <Text className={i_text_copy} fontSize="13px">
                                            {'$ ' + formatNumber(item.volDay, 2, 2)}
                                        </Text>
                                    </HStack>
                                )}
                                {!isMobile && (
                                    <HStack w="110px">
                                        <Text className={i_text_copy} fontSize="13px">
                                            {'$ ' + formatNumber(item.volWeek, 2, 2)}
                                        </Text>
                                    </HStack>
                                )}

                                {!isMobile && (
                                    <FavButton
                                        w="20px"
                                        h="20px"
                                        variant="base"
                                        isFav={favInfoKey(favInfo) in favorite.favToken}
                                        setIsFav={(isAdd: boolean) =>
                                            dispatch.favorite.modFavToken({
                                                isAdd,
                                                favInfo,
                                            })
                                        }
                                        boxSize="15px"
                                        m="0!important"
                                    />
                                )}
                            </HStack>
                        );
                    })
                ) : (
                    <Text className={i_text_copy} fontSize="15px" py="8px" color={colorTheme('gray.400', 'gray.0')}>
                        No Result
                    </Text>
                )}
            </VStack>
        </Card>
    );
};
