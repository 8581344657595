import { BoxProps, VStack, Text, useColorMode } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { PoolList } from '../../components/PoolList';
import { useEffect } from 'react';
import { useChain } from '../../../hooks/useChain';
import {
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
    ResponseIziSwapPoolSummaryRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { MultiPageData, MultiPageSetting } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';

type PoolsBlockProps = BoxProps;

// TODO remove this for duplicate overview
export const PoolsBlock: React.FC<PoolsBlockProps> = (props) => {
    const { ...rest } = props;
    const { chainId } = useChain();

    const { dashboard } = useSelector((state: RootState) => state);
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [setting, setSetting] = useSetState<MultiPageSetting>({
        page: 1,
        pageSize: 10,
    });
    const [poolData, setPoolData] = useSetState<MultiPageData<ResponseIziSwapPoolSummaryRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    useEffect(() => {
        getIziSwapGenericSummaryRecord<ResponseIziSwapPoolSummaryRecord[]>({
            page: setting.page,
            page_size: setting.pageSize,
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_LATEST,
            order_by: dashboard.poolSetting.orderBy,
        }).then((r) => {
            // TODO loading status
            const data = r.data.is_success ? r.data.data : [];
            const totalPage = r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1;
            setPoolData({ data, totalPage });
        });
    }, [setting, chainId, dashboard.poolSetting]);

    return (
        <VStack w={{ base: '100%', sm: '1150px' }} alignItems="left" mt="60px !important" spacing="20px" mb="40px !important" {...rest}>
            <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                All Pools
            </Text>

            <PoolList
                setting={setting}
                pageData={poolData}
                setCurrentPage={(page: number) => setSetting({ page })}
                setPageSize={(pageSize: number) => setSetting({ pageSize })}
                mb={{ base: '80px !important', sm: '0px !important' }}
            />
        </VStack>
    );
};
