import { Flex, VStack, useColorMode } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { getColorThemeSelector } from '../../utils/funcs';
import { config } from '../../config/announceBar/config';
import AnnouncementBar from '../AnnounceBar/AnnounceBar';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { useTokenList } from '../../hooks/useTokenConfig';
import { Loading } from '../../views/components/Loading';
import useIsMobile from '../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

export type PageLayoutProps = {
    header: ReactElement;
    body: ReactElement;
};

const PageLayout: React.FC<PageLayoutProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { height } = useWindowDimensions();
    const isMobile = useIsMobile();

    const bars = config.map((item: any, index: any) => {
        return <AnnouncementBar key={index} {...item}></AnnouncementBar>;
    });

    const { loading } = useTokenList();

    if (loading) {
        return <Loading />;
    }

    return (
        <VStack
            w="100%"
            //54px is the height of header
            h={height - 54}
            bg={colorTheme('#fbfbfb', 'rgba(19, 19, 19, 0.3)')}
            spacing="0"
            overflowY="scroll"
            flex={{ base: 'none !important', sm: '1 1' }}
        >
            {bars}
            <Flex
                px={{ base: '20px', sm: '40px', xlp1: '100px' }}
                h="100%"
                w="100%"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none !important',
                    },
                }}
                justifyContent={{ base: 'space-between', '2xl': 'center' }}
                //justifyContent="center"
                position="relative"
            >
                <VStack w={{ base: '100%', sm: '680px', xl: '1150px' }}>
                    {props.header}
                    {/* <Divider m="0 !important" /> */}
                    {props.body}
                </VStack>
            </Flex>
        </VStack>
    );
};

export default PageLayout;
