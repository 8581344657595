import { BoxProps, VStack, Text, useColorMode, HStack, Divider, Icon, Image, color } from '@chakra-ui/react';
import moment from 'moment';
import { FiExternalLink } from 'react-icons/fi';
import Card from '../../../components/Card/Card';
import { Pagination } from '../../../components/Pagination/Pagination';
import { useTokenList } from '../../../hooks/useTokenConfig';
import { ResponseIziTransRecord, TransRecordTypeEnum } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { MultiPageProps } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { DEFAULT_TIME_FORMAT, TokenSymbol } from '../../../types/mod';
import { viewTrans } from '../../../utils/blockchainExplorerUtils';
import { getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { FeeRate } from '../../components/FeeRate';
import { TokenIcons } from '../../components/TokenIcons';

type TransactionListProps = MultiPageProps<ResponseIziTransRecord> & BoxProps;

export const DetailTransactionList: React.FC<TransactionListProps> = (props) => {
    const { setting, pageData, setCurrentPage, setPageSize, children, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { getTokenInfo } = useTokenList();

    const trStyle = {
        className: { i_text_copy },
        fontSize: '12px',
        color: colorTheme('gray.400', 'gray.0'),
    } as unknown as BoxProps;

    const tabs = [
        {
            width: '250px',
            title: 'Pool/fees',
        },
        {
            width: '129px',
            title: 'Type',
        },
        {
            width: '340px',
            title: 'Transaction',
        },
        {
            width: '280px',
            title: 'Order Date',
        },
    ];
    return (
        <Card variant="base" w="1150px" alignItems="left" p="30px 20px 30px 20px" textAlign="left" {...rest}>
            <VStack spacing="20px" alignItems="left">
                {children}
                <HStack mt="30px !important">
                    {tabs.map((item, i: any) => {
                        return (
                            <Text key={i} pl={i === 0 ? '20px' : 'unset'} w={item.width} {...trStyle}>
                                {item.title}
                            </Text>
                        );
                    })}
                </HStack>
                <Divider />
                {pageData.data.map((item, i) => {
                    return (
                        <HStack key={i}>
                            <HStack w={tabs[0].width} mt="2px !important" pl="20px !important">
                                <TokenIcons
                                    tokenAddrA={item.tokenX_address}
                                    tokenAddrB={item.tokenY_address}
                                    initialToggle={false}
                                    chainId={item.chainId}
                                    showChainIcon={true}
                                />
                                <FeeRate
                                    tokenA={item.tokenX as TokenSymbol}
                                    tokenB={item.tokenY as TokenSymbol}
                                    feeTier={item.fee}
                                    initialToggle={false}
                                />
                            </HStack>

                            <HStack w={tabs[1].width}>
                                {item.type === TransRecordTypeEnum.IZI_SWAP_INC_LIQ && (
                                    <Text
                                        color={colorTheme('#358211', 'tertiary.100')}
                                        bg={colorTheme('#E9FCEA', '#274729')}
                                        px="10px"
                                        py="5px"
                                        borderRadius="3px"
                                        className={i_text_copy_bold}
                                        fontSize="13px"
                                    >
                                        {'Add'}
                                    </Text>
                                )}
                                {item.type === TransRecordTypeEnum.IZI_SWAP_DEC_LIQ && (
                                    <Text
                                        color={colorTheme('#AB501D ', 'tertiary.100')}
                                        bg={colorTheme('#FCF3E9', '#522929')}
                                        px="10px"
                                        py="5px"
                                        borderRadius="3px"
                                        className={i_text_copy_bold}
                                        fontSize="13px"
                                    >
                                        {'Remove'}
                                    </Text>
                                )}
                            </HStack>

                            <HStack w={tabs[2].width} className={i_text_copy} fontSize="13px">
                                <HStack w="45%">
                                    <Image
                                        boxSize="15px"
                                        src={getTokenInfo(item.chainId, item.tokenX_address)?.icon ?? '/assets/tokens/default.svg'}
                                    ></Image>
                                    <Text ml="6px">
                                        {formatNumber(item.amountX ?? 0, 2, 2, 2)} {item.tokenX}{' '}
                                    </Text>
                                </HStack>
                                <Text w="10%" mx="6px">
                                    +
                                </Text>
                                <HStack w="45%">
                                    <Image
                                        boxSize="15px"
                                        src={getTokenInfo(item.chainId, item.tokenY_address)?.icon ?? '/assets/tokens/default.svg'}
                                    ></Image>
                                    <Text ml="6px">
                                        {formatNumber(item.amountY ?? 0, 2, 2, 2)} {item.tokenY}{' '}
                                    </Text>
                                </HStack>
                            </HStack>

                            <HStack w={tabs[3].width} flex="1">
                                <Text className={i_text_copy} fontSize="13px">
                                    {moment(Number(item.timestamp) * 1000).format(DEFAULT_TIME_FORMAT)}
                                </Text>
                                <Text
                                    className={i_text_copy_bold}
                                    fontSize="13px"
                                    ml="60px !important"
                                    color={colorTheme('#001AFF', '#5D8AFE')}
                                >
                                    {truncateWalletAddress(item.txHash, 6, 3)}
                                </Text>
                                <Icon as={FiExternalLink} onClick={() => viewTrans(item.chainId, item.txHash)} boxSize="16px" />
                            </HStack>
                        </HStack>
                    );
                })}

                <Pagination
                    totalPage={pageData.totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="50px !important"
                />
            </VStack>
        </Card>
    );
};
