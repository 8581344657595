import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
import { TOKEN_CONFIG } from '../../../config/bizConfig';
import { getTokenPriceBySymbol, getTokenPriceBySymbolList } from '../../../net/iZUMi-endpoints/src/restful/tokenPrice';

export interface TokenPriceMap {
    [tokenSymbol: string]: number
}

export interface TokenState {
    tokenPriceMap: TokenPriceMap;
}

export const token = createModel<RootModel>()({
	state: {
		tokenPriceMap: {
        }
	} as TokenState,
	reducers: {
        setSingleTokenPrice: (state: TokenState, {tokenSymbol, price}: {tokenSymbol: string, price: number}) => produce(state, draft => {
            draft.tokenPriceMap[tokenSymbol.toUpperCase()] = price;
        }),
        setTokenPriceMap: (state: TokenState, tokenPriceMap: TokenPriceMap) => produce(state, draft => {
            draft.tokenPriceMap = {...draft.tokenPriceMap, ...tokenPriceMap};
		}),
	},
	effects: (dispatch) => ({
        async fetchTokenPrice(tokenSymbol: string): Promise<number> {
            // TODO unwrap response on aop, api error process
            const result = await getTokenPriceBySymbol(tokenSymbol);
            const price = result.data.data;
            dispatch.token.setSingleTokenPrice({tokenSymbol, price});
            return price;
        },
        async fetchTokenPriceIfMissing(tokenSymbol: string, rootState): Promise<number> {
            if (!tokenSymbol) {
                // console.warn('empty tokenSymbol for fetchTokenPriceIfMissing');
                return 0;
            }
            const tokenPrice = rootState.token.tokenPriceMap[tokenSymbol.toUpperCase()];
            return tokenPrice ?? (() => dispatch.token.fetchTokenPrice(tokenSymbol))();
        },
        async refreshTokenPrice(_, rootState): Promise<void> {
            const stateTokenSymbolList = Object.keys(rootState.token.tokenPriceMap);
            const tokenSymbolList = stateTokenSymbolList.length > 0 ? stateTokenSymbolList : TOKEN_CONFIG.INITIAL_FETCH_TOKEN_LIST;

            return getTokenPriceBySymbolList(tokenSymbolList.filter(t => t && t.length > 0)).then(result => {
                const tokenPriceDict = result.data.data;
                if (tokenPriceDict) {
                    dispatch.token.setTokenPriceMap(tokenPriceDict);
                }
            });
        },
	})
});