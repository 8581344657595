import { Box, Image } from '@chakra-ui/react';
import { getChain } from '../../config/chains';
import { useTokenList } from '../../hooks/useTokenConfig';

export interface TokenIconsProps {
    tokenAddrA: string;
    tokenAddrB: string;
    initialToggle?: boolean;
    chainId: number;
    showChainIcon?: boolean;
    size?: string;
}

export const TokenIcons: React.FC<TokenIconsProps> = (props) => {
    const { tokenAddrA, tokenAddrB, initialToggle, chainId, showChainIcon, size } = props;
    const { getTokenInfo } = useTokenList();

    return (
        <Box key="CoinIcons" position="relative" h={size === 'lg' ? '48px' : '32px'} w={size === 'lg' ? '48px' : '32px'} flexShrink={0}>
            {showChainIcon && chainId && (
                <Image
                    position="absolute"
                    top="0"
                    right="0"
                    borderRadius={size === 'lg' ? '6px' : '4px'}
                    h={size === 'lg' ? '12px' : '8px'}
                    w={size === 'lg' ? '12px' : '8px'}
                    flexShrink={0}
                    src={process.env.PUBLIC_URL + getChain(chainId)?.icon}
                />
            )}
            <Image
                position="absolute"
                top="0"
                left="0"
                borderRadius={size === 'lg' ? '2px' : '2px'}
                h={size === 'lg' ? '30px' : '20px'}
                w={size === 'lg' ? '30px' : '20px'}
                flexShrink={0}
                src={
                    process.env.PUBLIC_URL +
                    (getTokenInfo(chainId, initialToggle ? tokenAddrB : tokenAddrA)?.icon ?? '/assets/tokens/default.svg')
                }
            />

            <Image
                position="absolute"
                bottom="0"
                right="0"
                borderRadius={size === 'lg' ? '2px' : '2px'}
                h={size === 'lg' ? '36px' : '24px'}
                w={size === 'lg' ? '36px' : '24px'}
                flexShrink={0}
                src={
                    process.env.PUBLIC_URL +
                    (getTokenInfo(chainId, initialToggle ? tokenAddrA : tokenAddrB)?.icon ?? '/assets/tokens/default.svg')
                }
            />
        </Box>
    );
};
