import { ChainId } from '../types/mod';
import placeholder from '../assets/placeholder.png';

export interface BaseChain {
    id: number;
    name: string;
    tokenSymbol: string;
    icon: string;
    scanUrl: string;
    scanName: string;
    vmType: string;
    rpcUrl: string;
    blockDelta?: number; // time for producing a new block
}

const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/chains/';

const initialChains: BaseChain[] = [
    {
        id: ChainId.VirtualAll,
        name: 'VirtualAll',
        tokenSymbol: 'ALL',
        icon: '/assets/tokens/virtualAll.svg',
        scanUrl: 'None',
        scanName: 'None',
        vmType: 'EVM',
        rpcUrl: 'None',
    },
    {
        id: ChainId.EthereumMainnet,
        name: 'Ethereum',
        tokenSymbol: 'ETH',
        icon: '/assets/tokens/eth.png',
        scanUrl: 'https://etherscan.io/',
        scanName: 'EtherScan',
        vmType: 'EVM',
        rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        blockDelta: 12,
    },
    {
        id: ChainId.Optimism,
        name: 'Optimism',
        tokenSymbol: 'wETH',
        icon: '/assets/tokens/OP.svg',
        scanUrl: 'https://optimistic.etherscan.io/',
        scanName: 'OptimismScan',
        vmType: 'EVM',
        rpcUrl: 'https://mainnet.optimism.io',
    },
    {
        id: ChainId.BSC,
        name: 'BSC',
        tokenSymbol: 'BNB',
        icon: '/assets/tokens/bnb.png',
        scanUrl: 'https://bscscan.com/',
        scanName: 'BscScan',
        vmType: 'EVM',
        rpcUrl: 'https://bsc-dataseed.binance.org',
        blockDelta: 3,
    },
    {
        id: ChainId.Aurora,
        name: 'Aurora Chain',
        tokenSymbol: 'ETH',
        icon: '/assets/tokens/aurora.png',
        scanUrl: 'https://aurorascan.dev/',
        scanName: 'AuroraScan',
        vmType: 'EVM',
        rpcUrl: 'https://mainnet.aurora.dev',
        blockDelta: 1.5,
    },
    {
        id: ChainId.Gatechain,
        name: 'Gatechain',
        tokenSymbol: 'GT',
        icon: '/assets/tokens/GT.png',
        scanUrl: 'https://gatescan.org/',
        scanName: 'GateScan',
        vmType: 'EVM',
        rpcUrl: 'https://evm.gatenode.cc',
    },
    {
        id: ChainId.BSCTestnet,
        name: 'BSC Testnet',
        tokenSymbol: 'BNBt',
        icon: '/assets/tokens/bsc.png',
        scanUrl: 'https://testnet.bscscan.com/',
        scanName: 'TestnetBscScan',
        vmType: 'EVM',
        rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    },
    {
        id: ChainId.AuroraTestnet,
        name: 'Aurora Testnet',
        tokenSymbol: 'ETH',
        icon: '/assets/tokens/aurora.png',
        scanUrl: 'https://testnet.aurorascan.dev/',
        scanName: 'TestnetAuroraScan',
        vmType: 'EVM',
        rpcUrl: 'https://testnet.aurora.dev',
        blockDelta: 1.5,
    },
    {
        id: ChainId.Heco,
        name: 'Heco',
        tokenSymbol: 'HT',
        icon: '/assets/tokens/heco.png',
        scanUrl: 'https://hecoinfo.com/',
        scanName: 'HecoInfo',
        vmType: 'EVM',
        rpcUrl: 'https://http-mainnet-node.huobichain.com',
    },
    {
        id: ChainId.Matic,
        name: 'Polygon',
        tokenSymbol: 'Matic',
        icon: '/assets/tokens/polygon.png',
        scanUrl: 'https://polygonscan.com/',
        scanName: 'PologonScan',
        vmType: 'EVM',
        rpcUrl: 'https://rpc-mainnet.maticvigil.com',
        blockDelta: 2,
    },
    {
        id: ChainId.Fantom,
        name: 'Fantom',
        tokenSymbol: 'FTM',
        icon: '/assets/tokens/fantom.png',
        scanUrl: 'https://ftmscan.com/',
        scanName: 'FtmScan',
        vmType: 'EVM',
        rpcUrl: 'https://rpcapi.fantom.network',
    },
    {
        id: ChainId.Rinkeby,
        name: 'Rinkeby',
        tokenSymbol: 'ETH',
        icon: '/assets/tokens/eth.png',
        scanUrl: 'https://rinkeby.etherscan.io/',
        scanName: 'rinkebyScan',
        vmType: 'EVM',
        rpcUrl: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        blockDelta: 14,
    },
    {
        id: ChainId.Arbitrum,
        name: 'Arbitrum',
        tokenSymbol: 'AETH',
        icon: '/assets/tokens/arbitrum.svg',
        scanUrl: 'https://arbiscan.io/',
        scanName: 'ArbitrumScan',
        vmType: 'EVM',
        rpcUrl: 'https://arb1.arbitrum.io/rpc',
        blockDelta: 10,
    },
    {
        id: ChainId.MaticTestnet,
        name: 'Polygon Mumbai Testnet',
        tokenSymbol: 'Matic',
        icon: '/assets/tokens/polygon.png',
        scanUrl: 'https://mumbai.polygonscan.com/',
        scanName: 'PolygonMumbaiScan',
        vmType: 'EVM',
        rpcUrl: 'https://rpc-mumbai.maticvigil.com',
    },
    {
        id: ChainId.Harmony,
        name: 'Harmony Shard0',
        tokenSymbol: 'ONE',
        icon: '/assets/tokens/harmony.png',
        scanUrl: 'https://explorer.harmony.one/#/',
        scanName: 'HarmonyScan',
        vmType: 'EVM',
        rpcUrl: 'https://api.harmony.one',
    },
    {
        id: ChainId.ETC,
        name: 'Ethereum Classic',
        tokenSymbol: 'ETC',
        icon: baseURL + 'etc.png',
        scanUrl: 'https://blockscout.com/etc/mainnet/',
        scanName: 'EthereumClassicScan',
        vmType: 'EVM',
        rpcUrl: 'https://www.ethercluster.com/etc',
    },
    {
        id: ChainId.Cronos,
        name: 'Cronos',
        tokenSymbol: 'CRO',
        icon: baseURL + 'cronos.png',
        scanUrl: 'https://cronoscan.com/',
        scanName: 'CronoScan',
        vmType: 'EVM',
        rpcUrl: 'https://node.croswap.com/rpc',
    },
    {
        id: ChainId.ZkSyncAlphaTest,
        name: 'ZkSyncAlphaTest',
        tokenSymbol: 'ETH',
        icon: baseURL + 'zksync.png',
        scanUrl: 'https://zksync2-testnet.zkscan.io/',
        scanName: 'zkSync scan',
        vmType: 'ZKVM',
        rpcUrl: 'https://zksync2-testnet.zksync.dev',
    },
    {
        id: ChainId.MantleTest,
        name: 'MantleTest',
        tokenSymbol: 'BIT',
        icon: baseURL + 'mantle.png',
        scanUrl: 'https://explorer.testnet.mantle.xyz/',
        scanName: 'mantle test scan',
        vmType: 'EVM',
        rpcUrl: 'https://rpc.testnet.mantle.xyz',
    },
    {
        id: ChainId.ScrollTestL2,
        name: 'ScrollTestL2',
        tokenSymbol: 'ETH',
        icon: baseURL + 'scroll.png',
        scanUrl: 'https://prealpha.scroll.io/l2scan/',
        scanName: 'scroll test scan',
        vmType: 'EVM',
        rpcUrl: 'https://prealpha.scroll.io/l2',
    },
    {
        id: ChainId.Icplaza,
        name: 'icplaza',
        tokenSymbol: 'ICT',
        icon: baseURL + 'icplaza.png',
        scanUrl: 'https://browseevm.ic-plaza.org/',
        scanName: 'icplaza scan',
        vmType: 'EVM',
        rpcUrl: 'https://rpcmainnet.ic-plaza.org/',
    },
];

const lookupTableReducer = (table: Dictionary<number>, next: BaseChain, index: number) => {
    table[next.id] = index;
    return table;
};

const chains = (() => {
    const supportedIds = (() => {
        if (process.env.REACT_APP_ENV === 'production') {
            return {
                all: [ChainId.VirtualAll, ChainId.BSC, ChainId.Icplaza],
            };
        } else {
            return {
                all: [
                    ChainId.VirtualAll,
                    //ChainId.EthereumMainnet,
                    //ChainId.Optimism,
                    //ChainId.BSC,
                    //ChainId.Aurora,
                    //ChainId.Gatechain,
                    ChainId.BSCTestnet,
                    ChainId.AuroraTestnet,
                    //ChainId.Heco,
                    //ChainId.Matic,
                    //ChainId.Fantom,
                    //ChainId.Izumi,
                    //ChainId.Arbitrum,
                    //ChainId.MaticTestnet,
                    //ChainId.Harmony,
                    //ChainId.Rinkeby,
                    ChainId.ZkSyncAlphaTest,
                    ChainId.MantleTest,
                    ChainId.ScrollTestL2,
                ],
            };
        }
    })();

    const initialChainsLookupTable = initialChains.reduce(lookupTableReducer, {});
    const reducer = (endChains: BaseChain[], id: number) => {
        if (initialChainsLookupTable[id] !== undefined) {
            endChains.push(initialChains[initialChainsLookupTable[id]]);
        } else {
            console.error(`Chain ID not found: ${id}`);
        }
        return endChains;
    };

    return {
        all: supportedIds.all.reduce<BaseChain[]>(reducer, []),
    };
})();

export const chainLookupTable = {
    all: chains.all.reduce<Dictionary<number>>(lookupTableReducer, {}),
};

export const getChain = (chainId: ChainId) => {
    return chains.all.find((chain) => {
        return chain.id === chainId;
    });
};

export const getTxLink = (tx: string, chain: BaseChain) => {
    let url = chain.scanUrl;
    if (url.substring(url.length - 1) !== '/') {
        url += '/';
    }
    return url + 'tx/' + tx;
};

export const getScanUrlPrefix = (chainId: ChainId | undefined): string => {
    if (chainId === undefined) {
        return '';
    }
    const chain = chains.all.find((chain) => {
        return chain.id === chainId;
    });
    return chain?.scanUrl + 'address/';
};

export const getScanUrl = (chainId: ChainId | undefined): string => {
    if (chainId === undefined) {
        return '';
    }
    const chain = chains.all.find((chain) => {
        return chain.id === chainId;
    });
    return chain?.scanUrl ?? '';
};

const SECONDSPERDAY = 24 * 60 * 60;

export const blocksPerDay = (chainId: ChainId) => {
    const chain = getChain(chainId);
    return SECONDSPERDAY / (chain?.blockDelta ?? 10);
};

export default chains;
