import { BoxProps, VStack, Text, useColorMode } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useChain } from '../../../hooks/useChain';
import {
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
    ResponseIziSwapTokenSummaryRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { MultiPageData, MultiPageSetting } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { RootState } from '../../../state/store';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { TokenList } from '../../components/TokenList';

type TokensBlockProps = {
    variant?: any;
} & BoxProps;

// TODO remove this for duplicate overview
export const TokensBlock: React.FC<TokensBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useChain();

    const { dashboard } = useSelector((state: RootState) => state);
    const [setting, setSetting] = useSetState<MultiPageSetting>({
        page: 1,
        pageSize: 10,
    });
    const [tokenSummaryData, setTokenSummaryData] = useSetState<MultiPageData<ResponseIziSwapTokenSummaryRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    useEffect(() => {
        getIziSwapGenericSummaryRecord<ResponseIziSwapTokenSummaryRecord[]>({
            page: setting.page,
            page_size: setting.pageSize,
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_TOKEN_LATEST,
            order_by: dashboard.tokenSetting.orderBy,
        }).then((r) => {
            // TODO loading status
            const data = r.data.is_success ? r.data.data : [];
            const totalPage = r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1;
            setTokenSummaryData({ data, totalPage });
        });
    }, [setting, chainId, dashboard.tokenSetting]);

    return (
        <VStack w={{ base: '100%', sm: '1150px' }} alignItems="left" my="60px !important" spacing="20px" mb="40px !important" {...rest}>
            <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                All Tokens
            </Text>

            <TokenList
                setting={setting}
                pageData={tokenSummaryData}
                setCurrentPage={(page: number) => setSetting({ page })}
                setPageSize={(pageSize: number) => setSetting({ pageSize })}
                mb={{ base: '80px !important', sm: '0px !important' }}
            />
        </VStack>
    );
};
