import React from 'react';
import { Stack } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import { PoolsBlock } from '../Dashboard/components/PoolsBlock';
import { LiquidityFavoriteBlock } from './components/LiquidityFavoriteBlock';

import { LiquidityBlock } from './components/LiquidityBlock';

const Liquidity: React.FC = () => {
    return (
        <PageLayout
            header={<></>}
            body={
                <Stack w="100%">
                    <LiquidityFavoriteBlock />
                    <LiquidityBlock />
                </Stack>
            }
        />
    );
};

export default Liquidity;
