import React from 'react';
import { Stack, Box, Tabs, Tab, TabPanels, TabPanel, TabList, useColorMode } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import { Overviews } from './components/Overviews';
import { PoolsBlock } from './components/PoolsBlock';
import { TokensBlock } from './components/TokensBlock';
import { TransactionBlock } from './components/TransactionsBlock';
import { useSearchParams } from '../../hooks/useSearchParams';
import { isNumeric } from '../../iZUMi-UI-toolkit/src/utils/valid';
import { useChain } from '../../hooks/useChain';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import { RootDispatch } from '../../state/store';
import useIsMobile from '../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { getColorThemeSelector } from '../../iZUMi-UI-toolkit/src/utils/funcs';

const Dashboard: React.FC = () => {
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);

    const searchParams = useSearchParams();
    const chainIdParam = isNumeric(searchParams.get('chainId')) ? Number(searchParams.get('chainId')) : undefined;
    const chainId = useChain();
    const isMobile = useIsMobile();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    if (isNumeric(chainIdParam)) {
        if (Number(chainId) !== Number(chainIdParam)) {
            dispatch.announceBar.setChainId(chainIdParam as number);
        }
    }

    return isMobile ? (
        <PageLayout
            header={<></>}
            body={
                <Stack w="100%">
                    <Overviews />
                    <Tabs variant="unstyled" color="gray.500" mt="26px !important">
                        <TabList fontFamily="Montserrat-Medium">
                            <Tab
                                w="80px"
                                px="0px"
                                pr="10px"
                                whiteSpace="nowrap"
                                fontSize="12px !important"
                                _selected={{ color: colorTheme('#1B1C22', '#E3E3E4'), fontSize: '14px !important', fontWeight: 600 }}
                            >
                                {'Top Pools'}
                            </Tab>
                            <Tab
                                w="100px"
                                px="0px"
                                pr="10px"
                                whiteSpace="nowrap"
                                fontSize="12px !important"
                                _selected={{ color: colorTheme('#1B1C22', '#E3E3E4'), fontSize: '14px !important', fontWeight: 600 }}
                            >
                                {'Top Tokens'}
                            </Tab>
                            <Tab
                                w="100px"
                                px="0px"
                                pr="10px"
                                whiteSpace="nowrap"
                                fontSize="12px !important"
                                _selected={{ color: colorTheme('#1B1C22', '#E3E3E4'), fontSize: '14px !important', fontWeight: 600 }}
                            >
                                {' Transactions'}
                            </Tab>
                        </TabList>

                        <TabPanels mb="80px">
                            <TabPanel p="0px">
                                <PoolsBlock />
                            </TabPanel>
                            <TabPanel p="0px">
                                <TokensBlock />
                            </TabPanel>
                            <TabPanel p="0px">
                                <TransactionBlock />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                    <Box h="30px"></Box>
                </Stack>
            }
        />
    ) : (
        <PageLayout
            header={<></>}
            body={
                <Stack w="100%">
                    <Overviews />
                    <PoolsBlock />
                    <TokensBlock />
                    <TransactionBlock />
                    <Box h="30px"></Box>
                </Stack>
            }
        />
    );
};

export default Dashboard;
