import { useColorMode } from '@chakra-ui/react';
import { CandlestickData, CrosshairMode, IChartApi, ISeriesApi } from 'lightweight-charts';
import { CandlestickSeries, Chart } from 'lightweight-charts-react-wrapper';
import React, { useRef } from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import useWindowDimensions from '../../hooks/useWindowDimension';

import { getColorThemeSelector } from '../../utils/funcs';
import { formatNumber } from '../../utils/tokenMath';

type CandleChartProps = {
    themeColor: any;
    data: CandlestickData[];
};
const CandleChart: React.FC<CandleChartProps> = (props) => {
    const { themeColor, data } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const chartRef = useRef<IChartApi>(null);
    const candlesSeries = useRef<ISeriesApi<'Candlestick'>>(null);
    const isMobile = useIsMobile();
    const { width } = useWindowDimensions();
    return (
        <Chart
            ref={chartRef}
            width={isMobile ? width - 100 : 1100}
            height={290}
            layout={{
                background: {
                    color: themeColor,
                },
                textColor: colorTheme('#B3B3B3', '#6A598C'),
                fontFamily: 'NotoSansArmenian-Regular',
            }}
            crosshair={{ mode: CrosshairMode.Normal }}
            grid={{
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: false,
                },
            }}
            timeScale={{ timeVisible: true, borderColor: colorTheme('#D9D9E7', '#6A598C'), barSpacing: 12 }}
            rightPriceScale={{ borderColor: colorTheme('#D9D9E7', '#6A598C') }}
        >
            <CandlestickSeries
                data={data}
                reactive={true}
                ref={candlesSeries}
                priceFormat={{
                    type: 'custom',
                    formatter: (price: number) => {
                        return formatNumber(price, 2, 2, 2);
                    },
                    minMove: 0.00000001,
                }}
                upColor="#12931F"
                downColor="#CB1414"
            />
        </Chart>
    );
};

export default React.memo(CandleChart);
