import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
export interface TransactionsState {
    control: TransactionsControl;
    currentTab: string;
}
export interface SearchKeys {
    address: string,
    pool: string,
    token: string,
    timeStart: any,
    timeEnd: any,
    withContract: string
}

export interface TransactionsControl {
    searchKeys: SearchKeys;
}
export interface TransactionData {
    address?: string;
    pool?: string;
    token?: string;
    timeStart?: string;
    timeEnd?: string;
}


export const transaction = createModel<RootModel>()({
    state: {
        TransactionData: {},
        control: {
            searchKeys: { address: '', pool: '', token: '', timeStart: '', timeEnd: '', withContract: '' },
        },
        currentTab: '',
    } as TransactionsState,
    reducers: {
        setControl: (state: TransactionsState, control: TransactionsControl) => produce(state, draft => {
            draft.control = { ...control };
        }),
        setSearchKey: (state: TransactionsState, key: SearchKeys) => produce(state, draft => {
            draft.control.searchKeys = key;
        }),
        seCurrentTab: (state: TransactionsState, value: string) => produce(state, draft => {
            draft.currentTab = value;
        }),
    },
});
