import Web3 from 'web3';
import { HttpProviderOptions } from 'web3-core-helpers';
import { ChainId } from '../types/mod';
import chains, { chainLookupTable, getChain } from '../config/chains';

const RPC_URL = (process.env.REACT_APP_ENV === 'production' ? getChain(ChainId.BSC)?.rpcUrl : getChain(ChainId.BSCTestnet)?.rpcUrl) ?? '';

export const httpProvider = new Web3.providers.HttpProvider(RPC_URL, {
    timeout: 10000,
} as HttpProviderOptions);

/**
 * @deprecated
 */
export const web3NoAccountDefault = new Web3(httpProvider);

export const getWeb3NoAccount = (chainId: ChainId): Web3 => {
    const httpProvider = new Web3.providers.HttpProvider(getChain(chainId)?.rpcUrl ?? '', {
        timeout: 10000,
    } as HttpProviderOptions);
    return new Web3(httpProvider);
};

export const web3IsNoAccount = (web3: Web3) => {
    if (web3) {
        // @ts-ignore
        if (web3._provider.host) {
            return true;
        }
        return false;
    }
    return true;
};

export default web3NoAccountDefault;
