import Web3 from 'web3';

export const toCheckSumAddress = (address: string) => {
    try {
        const checkedAddress = Web3.utils.toChecksumAddress(address);
        return checkedAddress;
    } catch (e) {
        // console.log(e)
        return;
    }
};
