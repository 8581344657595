import React from 'react';
import { Stack } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import { FavoriteBlock } from './components/TokenFavoriteBlock';
import { TokensBlock } from './components/TokensBlock';

const Tokens: React.FC = () => {
    return (
        <PageLayout
            header={<></>}
            body={
                <Stack w="100%">
                    <FavoriteBlock />
                    <TokensBlock />
                </Stack>
            }
        />
    );
};

export default Tokens;
