import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import chains from '../config/chains';
import Web3 from 'web3';

export enum ConnectorNames {
    Injected = 'injected',
    WalletConnect = 'walletconnect',
}

const supportedChainIds = chains.all.map((chain) => chain.id);

const injected = new InjectedConnector({
    supportedChainIds: supportedChainIds,
});

const walletconnect = new WalletConnectConnector({
    rpc: chains.all.reduce((acc, chain) => Object.assign(acc, { [chain.id]: chain.rpcUrl }), {}),
    qrcode: true,
    pollingInterval: 12000,
});

const connectors = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect,
};

export const connectorsArray = [
    {
        name: ConnectorNames.Injected,
        displayName: 'Metamask',
        connector: injected,
        url: 'metamask.png',
    },
    {
        name: ConnectorNames.WalletConnect,
        displayName: 'Wallet Connect',
        connector: walletconnect,
        url: 'mathwallet.png',
    },
];

export const getLibrary = (provider: any): Web3 => {
    return provider;
};

export default connectors;
