import { Box, BoxProps, Image } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { getChain } from '../../config/chains';
import { useTokenList } from '../../hooks/useTokenConfig';

export type Props = {
    tokenAddr: string;
    chainId: number;
    size?: string;
} & BoxProps;

export const TokenIconWithChain: React.FC<Props> = (props) => {
    const { tokenAddr, chainId, size, ...rest } = props;
    const { getTokenInfo } = useTokenList();
    const history = useHistory();

    return (
        <Box
            key="CoinIcons"
            position="relative"
            h={size === 'lg' ? '48px' : '24px'}
            w={size === 'lg' ? '60px' : '30px'}
            flexShrink={0}
            {...rest}
        >
            {chainId && (
                <Image
                    position="absolute"
                    top="0"
                    right="0"
                    borderRadius={size === 'lg' ? '8px' : '4px'}
                    h={size === 'lg' ? '16px' : '8px'}
                    w={size === 'lg' ? '16px' : '8px'}
                    flexShrink={0}
                    src={process.env.PUBLIC_URL + getChain(chainId)?.icon}
                />
            )}
            <Image
                position="absolute"
                bottom="0"
                left="0"
                borderRadius={size === 'lg' ? '2px' : '2px'}
                h={size === 'lg' ? '48px' : '24px'}
                w={size === 'lg' ? '48px' : '24px'}
                flexShrink={0}
                src={process.env.PUBLIC_URL + (getTokenInfo(chainId, tokenAddr)?.icon ?? '/assets/tokens/default.svg')}
                cursor="pointer"
                onClick={() => {
                    history.push('/token?chainId=' + chainId + '&tokenAddress=' + tokenAddr);
                }}
            />
        </Box>
    );
};
