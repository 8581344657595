export const config = [
    // {
    //     text: 'hello world',
    //     icon: '',
    //     type: 'link',
    //     linkText: 'Check more.',
    //     onClick: null,
    //     index: 0,
    // },
];
