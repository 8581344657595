import { style, media } from 'typestyle';

export const i_h1 = style(
    {
        fontFamily: 'Montserrat-Bold',
        fontSize: '40px',
        fontStyle: 'normal',
        lineHeight: '42px',
        letterSpacing: '0em',
    },
    media(
        {
            maxWidth: 767,
            minWidth: 576,
        },
        {
            lineHeight: '40px',
            fontSize: '40px',
        }
    ),
    media(
        {
            maxWidth: 575,
        },
        {
            lineHeight: '36px',
            fontSize: '36px',
        }
    )
);

export const i_h2 = style(
    {
        fontFamily: 'Montserrat-Bold',
        fontSize: '32px',
        fontStyle: 'normal',
        lineHeight: '35px',
        letterSpacing: '0em',
    },
    media(
        {
            maxWidth: 767,
            minWidth: 576,
        },
        {
            fontSize: '28px',
            lineHeight: '32px',
        }
    ),
    media(
        {
            maxWidth: 575,
        },
        {
            fontSize: '24px',
            lineHeight: '31px',
        }
    )
);
export const i_h3 = style(
    {
        fontFamily: 'Montserrat',
        fontSize: '24px',
        fontStyle: 'normal',
        lineHeight: '25px',
        letterSpacing: '0em',
    },
    media(
        {
            maxWidth: 767,
            minWidth: 576,
        },
        {
            fontFamily: 'Montserrat-Bold',
            fontSize: '24px',
            lineHeight: '26px',
        }
    ),
    media(
        {
            maxWidth: 575,
        },
        {
            fontFamily: 'Montserrat-Bold',
            fontSize: '20px',
            lineHeight: '23px',
        }
    )
);
export const i_h4 = style(
    {
        fontFamily: 'Montserrat-Bold',
        fontSize: '18px',
        fontStyle: 'normal',
        lineHeight: '22px',
        letterSpacing: '0em',
    },
    media(
        {
            maxWidth: 767,
            minWidth: 576,
        },
        {
            fontSize: '18px',
            lineHeight: '20px',
        }
    ),
    media(
        {
            maxWidth: 575,
        },
        {
            fontSize: '14px',
            lineHeight: '15.5px',
        }
    )
);
export const i_h5 = style(
    {
        fontFamily: 'Montserrat-Bold',
        fontSize: '14px',
        fontStyle: 'normal',
        lineHeight: '17px',
        letterSpacing: '0em',
    },
    media(
        {
            maxWidth: 767,
            minWidth: 576,
        },
        {
            fontSize: '14px',
            lineHeight: '14px',
        }
    ),
    media(
        {
            maxWidth: 575,
        },
        {
            fontSize: '14px',
            lineHeight: '14px',
        }
    )
);
export const i_text_d = style(
    {
        fontFamily: 'Montserrat-Medium',
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: '24px',
        letterSpacing: '0em',
    },
    media(
        {
            maxWidth: 767,
            minWidth: 576,
        },
        {
            fontSize: '15px',
            lineHeight: '25.5px',
        }
    ),
    media(
        {
            maxWidth: 575,
        },
        {
            fontSize: '15px',
            lineHeight: '20px',
        }
    )
);
export const i_text_copy = style(
    {
        fontFamily: 'Montserrat-Medium',
        fontSize: '12px',
        fontStyle: 'normal',
        lineHeight: '15px',
        letterSpacing: '0.02em',
    },
    media(
        {
            maxWidth: 767,
            minWidth: 576,
        },
        {
            fontSize: '12px',
            lineHeight: '25.5px',
        }
    ),
    media(
        {
            maxWidth: 575,
        },
        {
            fontSize: '12px',
            lineHeight: '14px',
        }
    )
);
export const i_text_copy_bold = style(
    {
        fontFamily: 'Montserrat-Bold',
        fontSize: '12px',
        fontStyle: 'normal',
        lineHeight: '15px',
        letterSpacing: '0.02em',
        //textAlign: 'left',
    },
    media(
        {
            maxWidth: 767,
            minWidth: 576,
        },
        {
            fontSize: '12px',
            lineHeight: '15px',
        }
    ),
    media(
        {
            maxWidth: 575,
        },
        {
            fontSize: '12px',
            lineHeight: '15px',
        }
    )
);
