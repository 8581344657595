import BigNumber from 'bignumber.js';
import { BIG_TEN } from './bigNumber';

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getAmount = (decimalAmount: BigNumber, decimals = 18) => {
    return new BigNumber(decimalAmount).times(BIG_TEN.pow(decimals));
};

export const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
    return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals));
};

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2, type = 0) => {
    if (number === 0) {
        return '0';
    }
    try {
        let text = '';
        if (type === 1) {
            if (number > 1000000000) {
                text = 'b';
                number = number / 1000000000;
            } else if (number > 1000000) {
                text = 'm';
                number = number / 1000000;
            } else if (number > 1000) {
                text = 'k';
                number = number / 1000;
            }
        }

        if (type === 2) {
            const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            };
            if (number > 100000) {
                options.maximumFractionDigits = 0;
                options.minimumFractionDigits = 0;
            } else if (number > 10000) {
                options.maximumFractionDigits = 1;
                options.minimumFractionDigits = 1;
            } else if (number > 1000) {
                options.maximumFractionDigits = 2;
                options.minimumFractionDigits = 2;
            } else if (number > 100) {
                options.maximumFractionDigits = 3;
                options.minimumFractionDigits = 2;
            } else if (number > 10) {
                options.maximumFractionDigits = 4;
                options.minimumFractionDigits = 2;
            } else if (number > 1) {
                options.maximumFractionDigits = 5;
                options.minimumFractionDigits = 2;
            } else if (number > 0.1) {
                options.maximumFractionDigits = 6;
                options.minimumFractionDigits = 2;
            } else if (number > 0.01) {
                options.maximumFractionDigits = 7;
                options.minimumFractionDigits = 2;
            } else {
                options.maximumFractionDigits = 7;
                options.minimumFractionDigits = 2;
            }

            return number.toLocaleString(undefined, options);
        }

        //if (number > 1) {
        if (number) {
            const options = {
                minimumFractionDigits: minPrecision,
                maximumFractionDigits: maxPrecision,
            };
            return number.toLocaleString(undefined, options) + text;
        } else {
            return number.toPrecision(2) + text;
        }
    } catch (e) {
        return number;
    }
};
