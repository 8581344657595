import { BoxProps, VStack, Text, useColorMode, Image, Center } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { TokenList } from '../../components/TokenList';
import Card from '../../../components/Card/Card';
import placeholder from '../../../assets/placeholder.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import {
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
    ResponseIziSwapTokenSummaryRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { useChain } from '../../../hooks/useChain';
import { useEffect } from 'react';
import { MultiPageData, MultiPageSetting } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';

type FavoriteBlockProps = {
    variant?: any;
} & BoxProps;

export const FavoriteBlock: React.FC<FavoriteBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useChain();

    const [setting, setSetting] = useSetState<MultiPageSetting>({
        page: 1,
        pageSize: 10,
    });
    const [tokenSummaryData, setTokenSummaryData] = useSetState<MultiPageData<ResponseIziSwapTokenSummaryRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    const {
        favorite: { favToken },
    } = useSelector((state: RootState) => state);

    useEffect(() => {
        const favPoolKeys = Object.keys(favToken);
        if (!chainId || favPoolKeys.length <= 0) {
            return;
        }
        if (favPoolKeys?.length <= 0) {
            setTokenSummaryData({ data: [], totalPage: 0 });
            return;
        }

        // TODO limit of fav in search
        const addr_list = favPoolKeys.map((k) => favToken[k].address).join(',');
        getIziSwapGenericSummaryRecord<ResponseIziSwapTokenSummaryRecord[]>({
            page: setting.page,
            page_size: setting.pageSize,
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_TOKEN_LATEST,
            addr_list,
            order_by: '-major',
        }).then((r) => {
            // TODO loading status
            const data = r.data.is_success ? r.data.data : [];
            const totalPage = r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1;
            setTokenSummaryData({ data, totalPage });
        });
    }, [chainId, favToken, setting]);

    return (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            mt={{ base: '20px !important', sm: '60px !important' }}
            spacing="20px"
            {...rest}
        >
            <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                Your Favorite
            </Text>

            {tokenSummaryData.data.length ? (
                <TokenList
                    setting={setting}
                    pageData={tokenSummaryData}
                    setCurrentPage={(page: number) => setSetting({ page })}
                    setPageSize={(pageSize: number) => setSetting({ pageSize })}
                />
            ) : (
                <Card variant="base" w="100%" alignItems="left" p="30px 20px 30px 20px" {...rest}>
                    <Center>
                        <VStack>
                            <Image
                                w="150px"
                                h="120px"
                                src={process.env.PUBLIC_URL + colorTheme('/assets/lightNotConnect.png', '/assets/darkNotConnect.png')}
                                fallbackSrc={placeholder}
                            />
                            <Text className={i_text_copy} fontSize="13px" color={colorTheme('gray.400', 'gray.400')}>
                                Favorite pools will be shown here.
                            </Text>
                        </VStack>
                    </Center>
                </Card>
            )}
        </VStack>
    );
};
