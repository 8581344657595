import { getScanUrl } from '../config/chains';
import { ChainId } from '../types/mod';

export const viewTrans = (chainId: ChainId, tx?: string) => window.open(`${getScanUrl(chainId)}tx/${tx?.toLowerCase()}`, '_blank');

export const viewAddress = (chainId: ChainId, addr?: string) =>
    window.open(`${getScanUrl(chainId)}address/${addr?.toLowerCase()}`, '_blank');

export const tradeAddress = (chainId: ChainId, tokenFromAddr: string, tokenToAddr: string) => {
    window.open(
        process.env.REACT_APP_ENV === 'development'
            ? `https://dex-swap.ic-plaza.org/trade/swap?tokenFrom=${tokenFromAddr}&tokenTo=${tokenToAddr}&chainId=${chainId}`
            : `https://dex-swap.ic-plaza.org/trade/swap?tokenFrom=${tokenFromAddr}&tokenTo=${tokenToAddr}&chainId=${chainId}`,
        '_blank'
    );
};

export const tradeTokenAddress = (chainId: ChainId, addr: string) => {
    window.open(
        process.env.REACT_APP_ENV === 'development'
            ? `https://dex-swap.ic-plaza.org/trade/swap?tokenFrom=${addr}&chainId=${chainId}`
            : `https://dex-swap.ic-plaza.org/trade/swap?tokenFrom=${addr}&chainId=${chainId}`,
        '_blank'
    );
};
