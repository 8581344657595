import { BoxProps, VStack, Text, useColorMode, HStack, Icon, Divider, Flex, Image } from '@chakra-ui/react';
import CustomButton from '../../../components/Buttons/CustomButton/CustomButton';
import Card from '../../../components/Card/Card';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { getColorThemeSelector } from '../../../utils/funcs';
import { FeeRate } from '../../components/FeeRate';
import { TokenIcons } from '../../components/TokenIcons';
import { i_text_copy, i_h4, i_h2, i_text_d } from '../../../style';
import { useEffect, useState } from 'react';
import { InRange } from '../components/InRange';
import { SecondTag } from '../components/SecondTag';
import { FavButton } from '../../components/FavButton';
import { ResponseIziSwapLiquidityRecord } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapLiquidity';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../state/store';
import { favInfoKey } from '../../../state/models/favorite/favorite';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useSwapPriceRange } from '../../components/TokenSwapPriceRange';
import { formatNumber } from '../../../utils/tokenMath';
import {
    getGenericIziSwapMetaRecord,
    MetaRecordTypeEnum,
    ResponseIziPoolRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { TokenInfoFormatted } from 'iziswap-sdk/lib/base/types';
import { isNumeric } from '../../../utils/valid';

export interface LiquidityDetailInfo {
    tokenX: TokenInfoFormatted;
    tokenY: TokenInfoFormatted;
    amountXDecimal: number;
    amountYDecimal: number;
    feeXDecimal: number;
    feeYDecimal: number;
    worthAmountX: number;
    worthAmountY: number;
    worthAmountXRate: number;
    worthFeeX: number;
    worthFeeY: number;
}

type InfoBlockProps = { data: Partial<ResponseIziSwapLiquidityRecord>; liquidityDetailInfo: LiquidityDetailInfo } & BoxProps;

export const LiquidityInfoBlock: React.FC<InfoBlockProps> = (props) => {
    const { data, liquidityDetailInfo, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const chainId = data.chainId ?? ChainId.BSC;

    const { favorite } = useSelector((state: RootState) => state);
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);

    const [poolInfo, setPoolInfo] = useState<Partial<ResponseIziPoolRecord>>({});

    useEffect(() => {
        getGenericIziSwapMetaRecord<ResponseIziPoolRecord[]>({
            chain_id: data.chainId,
            type: MetaRecordTypeEnum.IZI_SWAP_POOL,
            contract_addr: data.address,
        }).then((r) => {
            const poolResult = r.data.is_success ? r.data.data : [];
            setPoolInfo(poolResult?.[0] ?? {});
        });
    }, [data.address, data.chainId]);

    const entry = {
        nft_id: data.nft_id,
        tokenA: data.tokenX as TokenSymbol,
        tokenB: data.tokenY as TokenSymbol,
        tokenA_address: data.tokenX_address,
        tokenB_address: data.tokenY_address,
        chainId: chainId,
        feeTier: data.fee,
        liquidityValue: data.liquidity,
        price: poolInfo?.latestDealPrice ?? 0,
    };

    const favInfo = {
        chainId: data.nft_id ?? -1,
        address: data.address ?? '',
    };

    const { tokenInfoX, tokenInfoY, priceLeft, priceRight, setToggle, toggle } = useSwapPriceRange({
        tokenAddrA: entry.tokenA_address,
        tokenAddrB: entry.tokenB_address,
        chainId,
        maxPriceBPerA: data.priceLeft ?? 0,
        minPriceBPerA: data.priceRight ?? 0,
    });

    const OwnerBlock = [
        {
            width: '620px',
            title: 'Owner',
            value: data.owner,
        },
        {
            width: '95px',
            title: 'Min Tick',
            value: data.leftPt,
        },
        {
            width: '112px',
            title: 'Max Tick',
            value: data.rightPt,
        },
        {
            width: '180px',
            title: 'Liquidity Amount',
            value: data.liquidity,
        },
    ];

    return (
        <VStack w="100%" alignItems="left" mt="60px !important" spacing="20px" {...rest}>
            <HStack w="100%" mt="2px !important">
                <Card w="100%" h="62px">
                    <Flex
                        w="100%"
                        h="100%"
                        alignItems="center"
                        bg={colorTheme('linear-gradient(263.62deg, #F2F4FF 27.72%, rgba(242, 244, 255, 0) 72.47%)', '#232433')}
                        pl="26px"
                    >
                        <TokenIcons
                            tokenAddrA={entry.tokenA_address as string}
                            tokenAddrB={entry.tokenB_address as string}
                            initialToggle={false}
                            chainId={entry.chainId}
                            showChainIcon={true}
                            size="lg"
                        />

                        <FeeRate
                            ml="10px"
                            tokenA={entry.tokenA}
                            tokenB={entry.tokenB}
                            feeTier={entry.feeTier}
                            initialToggle={false}
                            size="lg"
                        />
                        {/* <InRange ml="44px"></InRange> */}
                        {/* <SecondTag ml="15px"></SecondTag> */}
                        <Flex alignItems="center" bgColor={colorTheme('#ffffff', '#34364B')} borderRadius="6px" ml="auto" mr="22px">
                            <Flex h="27px" alignItems="center" px="12px">
                                <Text className={i_text_copy}>ID: {entry.nft_id}</Text>
                            </Flex>
                        </Flex>
                        <Flex
                            w="38px"
                            h="27px"
                            mr="27px"
                            bgColor={colorTheme('#ffffff', '#34364B')}
                            borderRadius="6px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <FavButton
                                w="20px"
                                h="20px"
                                variant="base"
                                isFav={favInfoKey(favInfo) in favorite.favLiquidity}
                                setIsFav={(isAdd: boolean) =>
                                    dispatch.favorite.modFavLiquidity({
                                        isAdd,
                                        favInfo,
                                    })
                                }
                                cursor="pointer"
                                boxSize="15px"
                                m="0!important"
                            />
                        </Flex>
                    </Flex>
                </Card>
            </HStack>

            <Flex w="1150px" h="265px">
                <Card w="858px" h="100%" position="relative">
                    <Flex py="20px">
                        <Flex w="50%" direction="column" pl="47px">
                            <Text className={i_text_copy} color="gray.500" mt="5px">
                                Liquidity
                            </Text>
                            <Text className={i_h2} mt="5px">
                                {isNumeric(liquidityDetailInfo.worthAmountX + liquidityDetailInfo.worthAmountY) ? (
                                    <>
                                        <Text className={i_text_d} color="gray.600" as="span" mr="5px">
                                            ~
                                        </Text>
                                        {formatNumber(liquidityDetailInfo.worthAmountX + liquidityDetailInfo.worthAmountY, 2, 2)}
                                        <Text className={i_text_d} color="gray.600" as="span" ml="5px">
                                            USD
                                        </Text>
                                    </>
                                ) : (
                                    '-'
                                )}
                            </Text>
                            <Flex
                                w="350px"
                                h="62px"
                                mt="24px"
                                alignItems="center"
                                bgColor={colorTheme('gray.0', '#232433')}
                                borderRadius="6px"
                            >
                                <Image
                                    boxSize="21px"
                                    ml="11px"
                                    src={(toggle ? tokenInfoY : tokenInfoX)?.icon ?? '/assets/tokens/default.svg'}
                                ></Image>
                                <Text className={i_h4} fontFamily="Montserrat" ml="12px">
                                    {formatNumber(liquidityDetailInfo.amountXDecimal, 2, 4, 2)} {liquidityDetailInfo.tokenX?.symbol}
                                </Text>
                                <Flex
                                    ml="auto"
                                    mr="12px"
                                    px="6px"
                                    py="3px"
                                    borderRadius="3px"
                                    bgColor={colorTheme('secondary.100', '#303252')}
                                >
                                    {isNumeric(liquidityDetailInfo.worthAmountXRate) ? (
                                        <Text className={i_text_copy}>
                                            {formatNumber(liquidityDetailInfo.worthAmountXRate * 100, 2, 2)} %
                                        </Text>
                                    ) : (
                                        '-'
                                    )}
                                </Flex>
                            </Flex>
                            <Flex
                                w="350px"
                                h="62px"
                                alignItems="center"
                                bgColor={colorTheme('gray.0', '#232433')}
                                borderRadius="6px"
                                mt="11px"
                            >
                                <Image
                                    boxSize="21px"
                                    ml="11px"
                                    src={(toggle ? tokenInfoX : tokenInfoY)?.icon ?? '/assets/tokens/default.svg'}
                                ></Image>
                                <Text className={i_h4} fontFamily="Montserrat" ml="12px">
                                    {formatNumber(liquidityDetailInfo.amountYDecimal, 2, 4, 2)} {liquidityDetailInfo.tokenY?.symbol}
                                </Text>
                                <Flex
                                    ml="auto"
                                    mr="12px"
                                    px="6px"
                                    py="3px"
                                    borderRadius="3px"
                                    bgColor={colorTheme('secondary.100', '#303252')}
                                >
                                    {isNumeric(liquidityDetailInfo.worthAmountXRate) ? (
                                        <Text className={i_text_copy}>
                                            {formatNumber(100 - liquidityDetailInfo.worthAmountXRate * 100, 2, 2)} %
                                        </Text>
                                    ) : (
                                        '-'
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                        <Divider h="210px" orientation="vertical" mt="12px"></Divider>

                        <Flex w="50%" direction="column" pl="47px">
                            <Text className={i_text_copy} color="gray.500" mt="5px">
                                Price Range
                            </Text>
                            <Flex alignItems="center" mt="14px">
                                <Image boxSize="21px" src={tokenInfoX?.icon ?? '/assets/tokens/default.svg'}></Image>
                                <Text className={i_text_d} fontWeight="600" ml="9px">
                                    {tokenInfoX?.symbol}
                                </Text>
                                <Image w="60px" src={'/assets/Liquidity/rightArrow.svg'} mx="48px"></Image>
                                <Image boxSize="21px" src={tokenInfoY?.icon ?? '/assets/tokens/default.svg'}></Image>
                                <Text className={i_text_d} fontWeight="600" ml="9px">
                                    {tokenInfoY?.symbol}
                                </Text>
                                <CustomButton
                                    w="32px"
                                    h="26px"
                                    bgColor={colorTheme('#EBEAEC', '#3D4256')}
                                    color={colorTheme('#52466A', '#EBEAEC')}
                                    ml="20px"
                                    fontSize="14px"
                                    onClick={setToggle}
                                    bgImage={colorTheme(
                                        process.env.PUBLIC_URL + '/assets/Liquidity/whiteTypeChange.svg',
                                        process.env.PUBLIC_URL + '/assets/Liquidity/darkTypeChange.svg'
                                    )}
                                    bgSize="100%"
                                ></CustomButton>{' '}
                            </Flex>
                            <Flex mt="27px">
                                <Flex
                                    w="160px"
                                    h="135px"
                                    direction="column"
                                    alignItems="center"
                                    bgColor={colorTheme('gray.0', '#232433')}
                                    borderRadius="6px"
                                >
                                    <Text className={i_text_copy} color="gray.400" mt="21px">
                                        MIN
                                    </Text>
                                    <Text className={i_text_d} mt="16px">
                                        {formatNumber(priceLeft, 4, 4, 2)} {tokenInfoY?.symbol}{' '}
                                    </Text>
                                    <Text className={i_text_copy} color="gray.400" mt="24px">
                                        = 1 {tokenInfoX?.symbol}
                                    </Text>
                                </Flex>
                                <Flex
                                    w="160px"
                                    h="135px"
                                    direction="column"
                                    alignItems="center"
                                    bgColor={colorTheme('gray.0', '#232433')}
                                    borderRadius="6px"
                                    ml="12px"
                                >
                                    <Text className={i_text_copy} color="gray.400" mt="21px">
                                        MAX
                                    </Text>
                                    <Text className={i_text_d} mt="16px">
                                        {formatNumber(priceRight, 4, 4, 2)} {tokenInfoY?.symbol}{' '}
                                    </Text>
                                    <Text className={i_text_copy} color="gray.400" mt="24px">
                                        = 1 {tokenInfoX?.symbol}
                                    </Text>
                                </Flex>{' '}
                            </Flex>
                        </Flex>
                    </Flex>
                </Card>
                <Flex direction="column" ml="13px">
                    <Card w="282px" h="87px" pl="20px">
                        <Text className={i_text_copy} color="gray.500" mt="20px">
                            Current Price
                        </Text>
                        <HStack className={i_text_d} mt="10px" fontSize="14px">
                            <Image boxSize="12px" src={tokenInfoX?.icon ?? '/assets/tokens/default.svg'}></Image>
                            <Text color="gray.500">1 {entry.tokenA} </Text>
                            <Text>=</Text>
                            <Image boxSize="12px" src={tokenInfoY?.icon ?? '/assets/tokens/default.svg'}></Image>
                            <Text>
                                {formatNumber(toggle ? 1 / (entry.price ?? 1) : entry.price, 4, 4, 2)} {entry.tokenB}{' '}
                            </Text>
                        </HStack>
                    </Card>
                    <Card w="282px" h="166px" mt="12px" px="20px">
                        <Text className={i_text_copy} color="gray.500" mt="20px">
                            Fees Gained
                        </Text>
                        <Text className={i_text_d} fontWeight="600" mt="3px">
                            {/* 1,200 */}-
                            <Text as="span" className={i_text_d} color="gray.600" ml="5px">
                                USD
                            </Text>
                        </Text>
                        <Divider mt="11px"></Divider>
                        <Flex className={i_text_d} alignItems="center" mt="12px">
                            <Image boxSize="12px" src={(toggle ? tokenInfoY : tokenInfoX)?.icon ?? '/assets/tokens/default.svg'}></Image>
                            <Text ml="6px"> {entry.tokenA} </Text>
                            <Text ml="auto">{/* 0.122  */}-</Text>
                        </Flex>
                        <Flex className={i_text_d} alignItems="center" mt="12px">
                            <Image boxSize="12px" src={(toggle ? tokenInfoX : tokenInfoY)?.icon ?? '/assets/tokens/default.svg'}></Image>
                            <Text ml="6px"> {entry.tokenB} </Text>
                            <Text ml="auto">{/* 255,122  */}-</Text>
                        </Flex>
                    </Card>
                </Flex>
            </Flex>

            <Card w="1150px" h="80px" px="40px" py="10px">
                <Flex h="100%" alignItems="center">
                    {OwnerBlock.map((item, index) => {
                        return (
                            <Flex w={item.width} direction="column" key={index}>
                                <Text className={i_text_copy} color="gray">
                                    {item.title}
                                </Text>

                                <Flex alignItems="center">
                                    <Text className={i_text_d} mt="5px">
                                        {item.value}
                                    </Text>
                                    {item.title === 'Owner' && (
                                        <Image
                                            boxSize="12px"
                                            src={colorTheme('/assets/Liquidity/copy.svg', '/assets/Liquidity/darkCopy.svg')}
                                            ml="8px"
                                            mt="5px"
                                            cursor="pointer"
                                        ></Image>
                                    )}
                                </Flex>
                            </Flex>
                        );
                    })}
                </Flex>
            </Card>
        </VStack>
    );
};
