import { BoxProps, VStack, Text, useColorMode } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import { useEffect, useState } from 'react';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { PoolList } from '../../components/PoolList';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { ChainId } from '../../../types/mod';
import { memGetIziSwapMetaRecord, MetaRecordTypeEnum, ResponseIziPoolRecord } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { isNumeric } from '../../../utils/valid';
import {
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
    RequestIziSwapSummaryRecord,
    ResponseIziSwapPoolSummaryRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { MultiPageData, MultiPageSetting } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

type PoolsBlockProps = BoxProps;

export const PoolsBlock: React.FC<PoolsBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const [setting, setSetting] = useSetState<MultiPageSetting>({
        page: 1,
        pageSize: 10,
    });
    const [poolSummaryData, setPoolSummaryData] = useSetState<MultiPageData<ResponseIziSwapPoolSummaryRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    const [poolOfTokenRecord, setPoolOfTokenRecord] = useState<ResponseIziPoolRecord[]>([]);

    const searchParams = useSearchParams();
    const tokenAddress = searchParams.get('tokenAddress') || undefined;
    const chainId = isNumeric(searchParams.get('chainId')) ? Number(searchParams.get('chainId')) : ChainId.BSC;

    useEffect(() => {
        if (!chainId || !tokenAddress) {
            return;
        }

        memGetIziSwapMetaRecord<ResponseIziPoolRecord[]>({
            type: MetaRecordTypeEnum.IZI_SWAP_POOL,
            chain_id: chainId,
            pool_token_addr: tokenAddress,
            page_size: 100,
        }).then((r) => r.data.is_success && setPoolOfTokenRecord(r.data.data));
    }, [setting, chainId, tokenAddress]);

    useEffect(() => {
        if (poolOfTokenRecord.length <= 0) {
            return;
        }
        const queryParams: RequestIziSwapSummaryRecord = {
            page: setting.page,
            page_size: setting.pageSize,
            chain_id: chainId,
            addr_list: poolOfTokenRecord.map((r) => r?.address).join(','),
            type: SummaryRecordTypeEnum.IZI_POOL_LATEST,
            order_by: '-major',
        };
        getIziSwapGenericSummaryRecord<ResponseIziSwapPoolSummaryRecord[]>(queryParams).then((r) => {
            // TODO loading status
            const data = r.data.is_success ? r.data.data : [];
            const totalPage = r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1;
            setPoolSummaryData({ data, totalPage });
        });
    }, [setting, poolOfTokenRecord]);

    return (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            mt={{ base: '20px !important', sm: '60px !important' }}
            spacing="20px"
            {...rest}
        >
            {!isMobile && (
                <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                    Pools
                </Text>
            )}

            <PoolList
                setting={setting}
                pageData={poolSummaryData}
                setCurrentPage={(page: number) => setSetting({ page })}
                setPageSize={(pageSize: number) => setSetting({ pageSize })}
            />
        </VStack>
    );
};
