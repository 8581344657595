import { BoxProps, VStack, Text, useColorMode } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useChain } from '../../../hooks/useChain';
import { RootDispatch, RootState } from '../../../state/store';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import {
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
    ResponseIziSwapPoolSummaryRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { PoolList } from '../../components/PoolList';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { MultiPageData } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';

type PoolsBlockProps = BoxProps;

export const PoolsBlock: React.FC<PoolsBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { chainId } = useChain();
    const isMobile = useIsMobile();

    const { dashboard } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));

    const [poolSummaryData, setPoolSummaryData] = useSetState<MultiPageData<ResponseIziSwapPoolSummaryRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    useEffect(() => {
        getIziSwapGenericSummaryRecord<ResponseIziSwapPoolSummaryRecord[]>({
            page: dashboard.poolSetting.page,
            page_size: dashboard.poolSetting.pageSize,
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_LATEST,
            order_by: dashboard.poolSetting.orderBy,
        }).then((r) => {
            // TODO loading status
            const data = r.data.is_success ? r.data.data : [];
            const totalPage = r.data.is_success ? Math.ceil(r.data.total / dashboard.poolSetting.pageSize) : 1;
            setPoolSummaryData({ data, totalPage });
        });
    }, [dashboard.poolSetting, chainId]);

    return (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            mt={{ base: '16px !important', sm: '60px !important' }}
            spacing="20px"
            {...rest}
        >
            {!isMobile && (
                <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                    Top Pools
                </Text>
            )}

            <PoolList
                setting={dashboard.poolSetting}
                pageData={poolSummaryData}
                setCurrentPage={(page: number) => dispatch.dashboard.setPoolSetting({ ...dashboard.poolSetting, page })}
                setPageSize={(pageSize: number) =>
                    dispatch.dashboard.setPoolSetting({ ...dashboard.poolSetting, pageSize: Number(pageSize) })
                }
            />
        </VStack>
    );
};
