import { BoxProps, VStack, Text, useColorMode } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import { useEffect } from 'react';
import { TRANS_TABLE_CONFIG } from '../../../config/bizConfig';
import { useChain } from '../../../hooks/useChain';
import { getIziSwapLiquidityRecord, ResponseIziSwapLiquidityRecord } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapLiquidity';
import { MultiPageData, MultiPageSetting } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { LiquidityList } from './LiquidityList';

type PoolsBlockProps = BoxProps;

export const LiquidityBlock: React.FC<PoolsBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { chainId } = useChain();
    const [setting, setSetting] = useSetState<MultiPageSetting>({
        page: 1,
        pageSize: 10,
        searchParam: {},
    });
    const [liquidityData, setLiquidityData] = useSetState<MultiPageData<ResponseIziSwapLiquidityRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    useEffect(() => {
        getIziSwapLiquidityRecord({
            page: setting.page,
            page_size: setting.pageSize,
            chain_id: chainId,
            order_by: '-nft_id',
            ...setting.searchParam,
        }).then((r) => {
            // TODO loading status
            const data = r.data.is_success ? r.data.data : [];
            const totalPage = r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1;
            const limitTotalPage = Math.min(totalPage, TRANS_TABLE_CONFIG.MAX_LIST_ITEM / setting.pageSize);
            setLiquidityData({ data, totalPage: limitTotalPage });
        });
    }, [setting, chainId]);

    return (
        <VStack w={{ base: '100%', sm: '1150px' }} alignItems="left" mt="60px !important" spacing="20px" mb="40px !important" {...rest}>
            <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                Search Liquidity
            </Text>

            <LiquidityList
                setting={setting}
                pageData={liquidityData}
                setCurrentPage={(page: number) => setSetting({ page })}
                setPageSize={(pageSize: number) => setSetting({ pageSize })}
                setSearchParam={(searchParam: Record<string, string>) => setSetting({ searchParam })}
                showSearch={true}
                mb={{ base: '80px !important', sm: '0px !important' }}
            />
        </VStack>
    );
};
