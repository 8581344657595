import { BoxProps, VStack, Text, useColorMode } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import { useEffect } from 'react';
import { useChain } from '../../../hooks/useChain';
import { RootDispatch, RootState } from '../../../state/store';
import {
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
    ResponseIziSwapTokenSummaryRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { TokenList } from '../../components/TokenList';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { MultiPageData } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';

type TokensBlockProps = BoxProps;

export const TokensBlock: React.FC<TokensBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useChain();
    const isMobile = useIsMobile();

    const { dashboard } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));

    const [tokenSummaryData, setTokenSummaryData] = useSetState<MultiPageData<ResponseIziSwapTokenSummaryRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    useEffect(() => {
        getIziSwapGenericSummaryRecord<ResponseIziSwapTokenSummaryRecord[]>({
            page: dashboard.tokenSetting.page,
            page_size: dashboard.tokenSetting.pageSize,
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_TOKEN_LATEST,
            order_by: dashboard.tokenSetting.orderBy,
        }).then((r) => {
            // TODO loading status
            const data = r.data.is_success ? r.data.data : [];
            const totalPage = r.data.is_success ? Math.ceil(r.data.total / dashboard.tokenSetting.pageSize) : 1;
            setTokenSummaryData({ data, totalPage });
        });
    }, [dashboard.tokenSetting, chainId]);

    return (
        <VStack
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            mt={{ base: '16px !important', sm: '60px !important' }}
            spacing="20px"
            {...rest}
        >
            {!isMobile && (
                <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                    Top Tokens
                </Text>
            )}

            <TokenList
                setting={dashboard.tokenSetting}
                pageData={tokenSummaryData}
                setCurrentPage={(page: number) => dispatch.dashboard.setTokenSetting({ ...dashboard.tokenSetting, page })}
                setPageSize={(pageSize: number) =>
                    dispatch.dashboard.setTokenSetting({ ...dashboard.tokenSetting, pageSize: Number(pageSize) })
                }
            />
        </VStack>
    );
};
