import React from 'react';
import { BoxProps, HStack, Tag, Text, useColorMode } from '@chakra-ui/react';
import { Heading } from '../../components/Typography/Typography';
import { i_text_copy, i_text_copy_bold } from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';
import { TokenSymbol } from '../../types/mod';

export type FeeRateProps = {
    tokenA: TokenSymbol;
    tokenB: TokenSymbol;
    feeTier: any;
    initialToggle?: boolean;
    size?: string;
    showText?: boolean;
} & BoxProps;

export const FeeRate: React.FC<FeeRateProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { tokenA, tokenB, feeTier, initialToggle, ...rest } = props;

    return (
        <HStack w="160px" {...rest}>
            <Heading color={colorTheme('#1C1D22', '#F9F9FB')} level="5">
                {!initialToggle ? tokenA + '/' + tokenB : tokenB + '/' + tokenA}
            </Heading>
            <Tag m="0" bg={colorTheme('primary.50', '#3D4375')} color={colorTheme('primary.500', 'primary.300')} className={i_text_copy}>
                <Text className={i_text_copy_bold} fontSize="14px">
                    {' '}
                    {(feeTier / 10000).toFixed(2)}%{' '}
                </Text>
            </Tag>
        </HStack>
    );
};
