import { BoxProps, VStack, Text, HStack, useColorMode, Stack } from '@chakra-ui/react';
import Card from '../../../components/Card/Card';
import { i_h2, i_text_copy } from '../../../style';
import { ChangeValue } from '../../components/ChangeValue';
import { TVLChart } from '../../components/TVLChart';
import { VolumeChart } from '../../components/VolumeChart';
import { calRatio, getColorThemeSelector } from '../../../utils/funcs';
import { useEffect, useMemo, useState } from 'react';
import { useChain } from '../../../hooks/useChain';
import { formatNumber } from '../../../utils/tokenMath';
import { OverviewInfo, TimeNumberSeries } from '../../components/types';
import {
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
    ResponseIziSwapSummaryNormalRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import useIsMobile from '../../../hooks/useIsMobile';

type OverviewsProps = BoxProps;

export const Overviews: React.FC<OverviewsProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useChain();
    const isMobile = useIsMobile();
    const [latestPoolInfo, setLatestPoolInfo] = useState<ResponseIziSwapSummaryNormalRecord>({} as ResponseIziSwapSummaryNormalRecord);
    type NewType = ResponseIziSwapSummaryNormalRecord;

    const [lastDayPoolInfo, setLastDayPoolInfo] = useState<NewType>({} as ResponseIziSwapSummaryNormalRecord);
    const [tvlHistory, setTvlHistory] = useState<TimeNumberSeries>([]);
    const [volHistory, setVolHistory] = useState<TimeNumberSeries>([]);

    useEffect(() => {
        getIziSwapGenericSummaryRecord<ResponseIziSwapSummaryNormalRecord[]>({
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_OF_CHAIN_LATEST,
        }).then((r) => {
            const latest = r.data.is_success ? r.data.data : [];
            setLatestPoolInfo(latest[0]);
        });

        getIziSwapGenericSummaryRecord<ResponseIziSwapSummaryNormalRecord[]>({
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_OF_CHAIN_DAY_ARCHIVE,
            page_size: 60,
            order_by: '-time',
        }).then((r) => {
            const poolHistory = r.data.is_success ? r.data.data : [];
            setTvlHistory(poolHistory.map((r) => [r.timestamp * 1000, r.tvl]).sort((a, b) => a[0] - b[0]));
            setVolHistory(poolHistory.map((r) => [r.timestamp * 1000, r.volDay]).sort((a, b) => a[0] - b[0]));
            setLastDayPoolInfo(poolHistory[0]);
        });
    }, [chainId]);

    const overviewInfo: OverviewInfo = useMemo(() => {
        return {
            tvlLastedDay: latestPoolInfo?.tvl ?? 0,
            volLastedDay: latestPoolInfo?.volDay ?? 0,
            feesLastedDay: latestPoolInfo?.feesDay ?? 0,
            tvlDayOnDayRatio: calRatio(latestPoolInfo?.tvl ?? 0, lastDayPoolInfo?.tvl ?? 0),
            volDayOnDayRatio: calRatio(latestPoolInfo?.volDay ?? 0, lastDayPoolInfo?.volDay ?? 0),
            feesDayOnDayRatio: calRatio(latestPoolInfo?.feesDay ?? 0, lastDayPoolInfo?.feesDay ?? 0),
        };
    }, [latestPoolInfo, lastDayPoolInfo]);

    return (
        <VStack w={{ base: '100%', sm: '1150px' }} alignItems="left" mt={{ base: '20px', sm: '30px' }} spacing="20px" {...rest}>
            <Text className={i_h2} fontSize="18px">
                Overviews
            </Text>

            <Stack w="100%" spacing={{ base: '17px', sm: '2%' }} direction={{ base: 'column', sm: 'row' }}>
                <TVLChart
                    w={{ base: '100%', sm: '49%' }}
                    h="270px"
                    height="250px"
                    series={[
                        {
                            name: 'TVL',
                            data: tvlHistory,
                        },
                    ]}
                />
                <VolumeChart
                    w={{ base: '100%', sm: '49%' }}
                    h="270px"
                    height="250px"
                    series={[
                        {
                            name: 'Volume',
                            data: volHistory,
                        },
                    ]}
                />
            </Stack>

            <Card w="100%" variant="base" h={{ base: 'unset', sm: '58px' }} p={{ base: '22px 0px 20px 20px', sm: '22px 40px 20px 20px' }}>
                <Stack direction={{ base: 'column', sm: 'row' }} alignItems={{ base: 'start', sm: 'center' }} spacing="20px">
                    <HStack spacing="10px">
                        <Text className={i_text_copy} fontSize={{ base: '12px', sm: '16px' }} color={colorTheme('gray.400', 'gray.400')}>
                            Volume 24H:
                        </Text>
                        <Text className={i_text_copy} fontSize={{ base: '12px', sm: '16px' }} color={colorTheme('gray.800', 'gray.0')}>
                            $ {formatNumber(overviewInfo.volLastedDay)}
                        </Text>
                        <ChangeValue
                            value={overviewInfo.volDayOnDayRatio}
                            bracket={true}
                            percentage={true}
                            fontSize={isMobile ? '12px' : '16px'}
                            w={{ base: 'unset', sm: '130px' }}
                        />
                    </HStack>

                    <HStack spacing="10px">
                        <Text className={i_text_copy} fontSize={{ base: '12px', sm: '16px' }} color={colorTheme('gray.400', 'gray.400')}>
                            Fees 24H:
                        </Text>
                        <Text className={i_text_copy} fontSize={{ base: '12px', sm: '16px' }} color={colorTheme('gray.800', 'gray.0')}>
                            $ {formatNumber(overviewInfo.feesLastedDay)}
                        </Text>
                        <ChangeValue
                            value={overviewInfo.feesDayOnDayRatio}
                            bracket={true}
                            percentage={true}
                            fontSize={isMobile ? '12px' : '16px'}
                            w={{ base: 'unset', sm: '130px' }}
                        />
                    </HStack>

                    <HStack spacing="10px">
                        <Text className={i_text_copy} fontSize={{ base: '12px', sm: '16px' }} color={colorTheme('gray.400', 'gray.400')}>
                            TVL:
                        </Text>
                        <Text className={i_text_copy} fontSize={{ base: '12px', sm: '16px' }} color={colorTheme('gray.800', 'gray.0')}>
                            $ {formatNumber(overviewInfo.tvlLastedDay)}
                        </Text>
                        <ChangeValue
                            value={overviewInfo.tvlDayOnDayRatio}
                            bracket={true}
                            percentage={true}
                            fontSize={isMobile ? '12px' : '16px'}
                            w={{ base: 'unset', sm: '130px' }}
                        />
                    </HStack>
                </Stack>
            </Card>
        </VStack>
    );
};
