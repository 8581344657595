import { BoxProps, VStack, InputGroup, Input, InputLeftElement, useColorMode } from '@chakra-ui/react';
import Card from '../../components/Card/Card';
import { useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import * as globalStyle from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';
import { SearchResult } from './SearchResult';

type SearchProps = BoxProps;

export const Search: React.FC<SearchProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [searchWord, setSearchWord] = useState<string>('');
    const [showResult, setShowResult] = useState<boolean>(false);

    return (
        <VStack w={{ base: '230px', sm: '524px', xl: '824px' }} minH="41px" mt="9px !important" position="relative" {...rest}>
            <InputGroup position="absolute" top="0" mr="20px" ml="20px">
                <Input
                    variant="filled"
                    placeholder="Search tokens or pools"
                    size="sm"
                    className={globalStyle.i_text_copy_bold}
                    fontSize="12px"
                    borderRadius="6px"
                    onChange={(v) => setSearchWord(v.target.value)}
                    onBlur={() => setTimeout(() => setShowResult(false), 300)}
                    onFocus={() => setShowResult(true)}
                    bg={colorTheme('', '#272A35')}
                />
                <InputLeftElement h="100%" mt="0px !important">
                    <SearchIcon boxSize={5} />
                </InputLeftElement>
            </InputGroup>

            {showResult && (
                <Card minH="270px" variant="base" w={{ base: '100%', sm: '724px' }} position="absolute" top="40px" zIndex={100}>
                    <SearchResult word={searchWord}></SearchResult>
                </Card>
            )}
        </VStack>
    );
};
