import moment from 'moment';
import { FiExternalLink } from 'react-icons/fi';
import { BiTransferAlt } from 'react-icons/bi';
import { ReactElement, useState } from 'react';

import { FeeRate } from '../../components/FeeRate';
import { TokenIcons } from '../../components/TokenIcons';
import { Pagination } from '../../../components/Pagination/Pagination';
import { useChain } from '../../../hooks/useChain';
import useIsMobile from '../../../hooks/useIsMobile';
import { useTokenList } from '../../../hooks/useTokenConfig';
import { ResponseIziTransRecord, TransRecordTypeEnum } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { ResponseIziSwapPoolSummaryRecord } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { DEFAULT_TIME_FORMAT, TokenSymbol } from '../../../types/mod';
import { viewTrans } from '../../../utils/blockchainExplorerUtils';
import { getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { BoxProps, VStack, Text, useColorMode, HStack, Divider, Flex, Image, Icon, Stack } from '@chakra-ui/react';
import { MultiPageProps } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';

type PoolListProps = MultiPageProps<ResponseIziTransRecord> & BoxProps;

export const SwapList: React.FC<PoolListProps> = (props) => {
    const { setting, pageData, setCurrentPage, setPageSize, children, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const [priceToggle, setPriceToggle] = useState(new Array(pageData.data.length).fill(false));

    const trStyle = {
        className: { i_text_copy },
        fontSize: '12px',
        color: colorTheme('gray.400', 'gray.0'),
    } as unknown as BoxProps;

    const tabs = [
        {
            width: '226px',
            title: 'Pool/fees',
        },
        {
            width: '330px',
            title: 'Transaction',
        },
        {
            width: '112px',
            title: 'Avg. Price',
        },
        {
            width: '200px',
            title: 'Order Date',
        },
    ];

    const { getTokenInfo } = useTokenList();
    const { chainId } = useChain();

    const getActionCell = (r: ResponseIziTransRecord): ReactElement => {
        const TokenX = r.sellXEarnY ? r.tokenX : r.tokenY;
        const TokenY = r.sellXEarnY ? r.tokenY : r.tokenX;

        const AmountX = r.sellXEarnY ? (r.amountX as number) : (r.amountY as number);
        const AmountY = r.sellXEarnY ? (r.amountY as number) : (r.amountX as number);
        const ImageX =
            process.env.PUBLIC_URL +
            (getTokenInfo(r.chainId, r.sellXEarnY ? r.tokenX_address : r.tokenY_address)?.icon ?? '/assets/tokens/default.svg');
        const ImageY =
            process.env.PUBLIC_URL +
            (getTokenInfo(r.chainId, r.sellXEarnY ? r.tokenY_address : r.tokenX_address)?.icon ?? '/assets/tokens/default.svg');
        switch (r.type) {
            case TransRecordTypeEnum.IZI_SWAP_TOKEN:
                return (
                    <>
                        <Flex direction={{ base: 'column', sm: 'row' }}>
                            <Flex alignItems="center">
                                <Image boxSize="15px" src={ImageX}></Image>
                                <Text ml="6px" minW="105px" className={i_text_copy}>
                                    {formatNumber(AmountX, 2, 2, 2)} {TokenX}{' '}
                                </Text>
                            </Flex>

                            <Image src="/assets/Liquidity/rightArrow.svg" ml="6px" w="30px" mt={{ base: '5px', sm: '0px' }}></Image>
                            <Flex alignItems="center" mt={{ base: '5px', sm: '0px' }}>
                                <Image boxSize="15px" src={ImageY} ml={{ base: '0px !important', sm: '14px !important' }}></Image>
                                <Text ml="6px" className={i_text_copy}>
                                    {' '}
                                    {formatNumber(AmountY, 2, 2, 2)}{' '}
                                </Text>
                                <Text ml="3px" className={i_text_copy}>
                                    {' '}
                                    {TokenY}
                                </Text>
                            </Flex>
                        </Flex>
                    </>
                );

            default:
                return <></>;
        }
    };

    const getAvgPrice = (r: ResponseIziTransRecord, toggle: boolean): string => {
        const TokenX = r.sellXEarnY ? r.tokenX : r.tokenY;
        const TokenY = r.sellXEarnY ? r.tokenY : r.tokenX;

        const AmountX = (r.sellXEarnY ? (r.amountX as number) : (r.amountY as number)) as number;
        const AmountY = (r.sellXEarnY ? (r.amountY as number) : (r.amountX as number)) as number;

        const avg = formatNumber(toggle ? AmountY / AmountX : AmountX / AmountY, 2, 5, 2);

        switch (r.type) {
            case TransRecordTypeEnum.IZI_SWAP_TOKEN:
                return avg + '';

            default:
                return '';
        }
    };

    return (
        <Flex direction="column" variant="base" w="100%" alignItems="left" p="30px 20px 30px 20px" mt="10px" {...rest}>
            <VStack alignItems="left">
                {!isMobile && (
                    <HStack ml="20px">
                        {tabs.map((item, i: any) => {
                            return (
                                <Text key={i} w={item.width} {...trStyle}>
                                    {item.title}
                                </Text>
                            );
                        })}
                    </HStack>
                )}
                <Divider />
                {pageData.loading ? (
                    <Flex direction="column" mt="70px !important" alignItems="center">
                        <img width="20px" height="20px" src="/assets/Transaction/loading.gif" />
                        <Text className={i_text_copy} mt="16px">
                            Searching
                        </Text>
                    </Flex>
                ) : (
                    pageData.data.map((item: any, i: number) => {
                        return (
                            <Stack key={i} direction={{ base: 'column', sm: 'row' }}>
                                <HStack w={tabs[0].width} mt="2px !important" ml={{ base: '0px !important', sm: '20px !important' }}>
                                    <TokenIcons
                                        tokenAddrA={item.tokenX_address}
                                        tokenAddrB={item.tokenY_address}
                                        initialToggle={false}
                                        chainId={item.chainId}
                                        showChainIcon={true}
                                    />
                                    <FeeRate
                                        tokenA={item.tokenX as TokenSymbol}
                                        tokenB={item.tokenY as TokenSymbol}
                                        feeTier={item.fee}
                                        initialToggle={false}
                                    />
                                </HStack>

                                <HStack w={{ base: 'unset', sm: tabs[1].width }} cursor="pointer">
                                    {isMobile && (
                                        <Text w="80px" {...trStyle}>
                                            {tabs[1].title}
                                        </Text>
                                    )}
                                    <Flex color={colorTheme('#7F4AFE', '#B08EFF')} className={i_text_copy_bold} fontSize="13px">
                                        {getActionCell(item)}
                                    </Flex>
                                </HStack>

                                <HStack w={{ base: 'unset', sm: tabs[2].width }}>
                                    {isMobile && (
                                        <Text w="80px" {...trStyle}>
                                            {tabs[2].title}
                                        </Text>
                                    )}

                                    <Text className={i_text_copy} fontSize="13px">
                                        {getAvgPrice(item, priceToggle[i])}
                                    </Text>
                                    <Icon
                                        as={BiTransferAlt}
                                        onClick={() => {
                                            let tmp = [...priceToggle];
                                            tmp[i] = !tmp[i];
                                            setPriceToggle(tmp);
                                        }}
                                        boxSize="12px"
                                        cursor="pointer"
                                        _hover={{ opacity: 1 }}
                                        opacity={0}
                                    />
                                </HStack>

                                <HStack w={{ base: 'unset', sm: tabs[3].width }}>
                                    {isMobile && (
                                        <Text w="80px" {...trStyle}>
                                            {tabs[3].title}
                                        </Text>
                                    )}

                                    <Text className={i_text_copy} fontSize="13px">
                                        {moment(Number(item.timestamp) * 1000).format(DEFAULT_TIME_FORMAT)}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Flex>
                                        <Text className={i_text_copy_bold} fontSize="13px" minW="100px">
                                            {truncateWalletAddress(item.account, 6, 4)}
                                        </Text>
                                        <Icon
                                            as={FiExternalLink}
                                            ml="10px"
                                            onClick={() => viewTrans(item.chainId, item.txHash)}
                                            boxSize="16px"
                                            cursor="pointer"
                                        />
                                    </Flex>
                                </HStack>
                                {isMobile && <Divider />}
                            </Stack>
                        );
                    })
                )}

                <Pagination
                    totalPage={pageData.totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="50px !important"
                />
            </VStack>
        </Flex>
    );
};
