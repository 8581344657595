import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
import Web3 from 'web3';

export interface BlockState {
    currentBlock: number
    initialBlock: number
}

export const block = createModel<RootModel>()({
    state: {
        currentBlock: 0,
        initialBlock: 0
    } as BlockState,
    reducers: {
        setBlockNumber: (state: BlockState, blockNumber: number) => produce(state, draft => {
            if (state.initialBlock === 0) {
                draft.initialBlock = blockNumber;
            }
            draft.currentBlock = blockNumber;
        })
    },
	effects: (dispatch) => ({
        async fetchBlockNumber(web3: Web3): Promise<number> {
            const blockNumber = await web3.eth.getBlockNumber();
            dispatch.block.setBlockNumber(blockNumber);
            return blockNumber;
        }
	})
});
