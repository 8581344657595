import { BoxProps, Divider, VStack } from '@chakra-ui/react';
import { useSetState, useThrottleEffect } from 'ahooks';
import { useEffect, useState } from 'react';
import { useChain } from '../../hooks/useChain';
import { memGetIziSwapMetaRecord, MetaRecordTypeEnum, ResponseIziPoolRecord } from '../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import {
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
    RequestIziSwapSummaryRecord,
    ResponseIziSwapPoolSummaryRecord,
    ResponseIziSwapTokenSummaryRecord,
} from '../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { MidPoolList } from './MidPoolList';
import { MidTokenList } from './MidTokenList';

type SearchResultProps = {
    word: string;
} & BoxProps;

export const SearchResult: React.FC<SearchResultProps> = (props) => {
    const { word, ...rest } = props;
    const { chainId } = useChain();

    const [preSearchKeyWord, setPreSearchKeyWord] = useState<string>('');
    const [tokenSummaryList, setTokenSummaryList] = useState<ResponseIziSwapTokenSummaryRecord[]>([]);
    const [poolSummaryList, setPoolSummaryList] = useState<ResponseIziSwapPoolSummaryRecord[]>([]);

    const [tokenQuery, setTokenQuery] = useSetState<RequestIziSwapSummaryRecord>({
        page_size: 3,
        chain_id: chainId,
        type: SummaryRecordTypeEnum.IZI_POOL_TOKEN_LATEST,
        order_by: '-major',
    });

    const [poolQuery, setPoolQuery] = useSetState<RequestIziSwapSummaryRecord>({
        page_size: 3,
        chain_id: chainId,
        type: SummaryRecordTypeEnum.IZI_POOL_LATEST,
        order_by: '-major',
    });

    useThrottleEffect(
        () => {
            if (!word || !word.trim()) {
                return;
            }

            const searchWord = word.trim();
            if (searchWord.startsWith('0x')) {
                // TODO search related pool or token
                setTokenQuery({
                    contract_addr: searchWord,
                    addr_list: undefined,
                });
                setPoolQuery({
                    contract_addr: searchWord,
                    addr_list: undefined,
                });
            } else if (searchWord.length >= 2) {
                memGetIziSwapMetaRecord<ResponseIziPoolRecord[]>({
                    chain_id: chainId,
                    type: MetaRecordTypeEnum.IZI_SWAP_POOL,
                    pool_token_search: searchWord,
                    page_size: 100,
                }).then((r) => {
                    const poolList = r.data.is_success ? r.data.data : [];
                    if (poolList.length > 0) {
                        const searchWordReg = new RegExp(searchWord, 'i');
                        const tokenAddrList = poolList.map((p) =>
                            p.tokenX.search(searchWordReg) !== -1 ? p.tokenX_address : p.tokenY_address
                        );
                        setTokenQuery({
                            contract_addr: undefined,
                            addr_list: [...new Set(tokenAddrList)].join(','),
                        });
                        setPoolQuery({
                            contract_addr: undefined,
                            addr_list: poolList.map((r) => r.address).join(','),
                        });
                    }
                });
            }

            if (preSearchKeyWord !== searchWord) {
                setPoolSummaryList([]);
                setTokenSummaryList([]);
                setPreSearchKeyWord(searchWord);
            }
        },
        [word],
        { wait: 800 }
    );

    useEffect(() => {
        if (!tokenQuery.contract_addr && !tokenQuery.addr_list) {
            return;
        }
        getIziSwapGenericSummaryRecord<ResponseIziSwapTokenSummaryRecord[]>(tokenQuery).then((r) => {
            const tokenSummaryList = r.data.is_success ? r.data.data : [];
            setTokenSummaryList(tokenSummaryList);
        });
    }, [tokenQuery]);

    useEffect(() => {
        if (!poolQuery.contract_addr && !poolQuery.addr_list) {
            return;
        }
        getIziSwapGenericSummaryRecord<ResponseIziSwapPoolSummaryRecord[]>(poolQuery).then((r) => {
            const poolSummaryList = r.data.is_success ? r.data.data : [];
            setPoolSummaryList(poolSummaryList);
        });
    }, [poolQuery]);

    return (
        <VStack w="100%" h="100%" borderRadius="6px" {...rest}>
            <MidTokenList tokenSummaryList={tokenSummaryList} />
            <Divider width="95%" py="8px" />
            <MidPoolList poolSummaryList={poolSummaryList} />
        </VStack>
    );
};
