import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    BoxProps,
    VStack,
    Text,
    useColorMode,
    HStack,
    Divider,
    Flex,
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Input,
    InputGroup,
    Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../../components/Buttons/CustomButton/CustomButton';
import Card from '../../../components/Card/Card';
import { Pagination } from '../../../components/Pagination/Pagination';
import useIsMobile from '../../../hooks/useIsMobile';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { ResponseIziSwapLiquidityRecord } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapLiquidity';
import { MultiPageProps } from '../../../net/iZUMi-endpoints/src/restful/types/multiPage';
import { favInfoKey } from '../../../state/models/favorite/favorite';
import { RootState, RootDispatch } from '../../../state/store';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { TokenSymbol } from '../../../types/mod';
import { getColorThemeSelector } from '../../../utils/funcs';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { FavButton } from '../../components/FavButton';
import { FeeRate } from '../../components/FeeRate';
import { TokenIcons } from '../../components/TokenIcons';
import { TokenSwapPriceRange } from '../../components/TokenSwapPriceRange';

type PoolListProps = MultiPageProps<ResponseIziSwapLiquidityRecord> & {
    setSearchParam?: (searchParam: Record<string, string>) => void;
    showSearch: boolean;
} & BoxProps;

export const LiquidityList: React.FC<PoolListProps> = (props) => {
    const { setting, pageData, setCurrentPage, setPageSize, setSearchParam, showSearch, children, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const { favorite } = useSelector((state: RootState) => state);
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);

    const history = useHistory();
    const jumpPool = (e: ResponseIziSwapLiquidityRecord) => {
        history.push(`/pool?chainId=${e.chainId}&poolAddress=${e.address}`);
    };
    const jumpLiquidity = (e: ResponseIziSwapLiquidityRecord) => {
        history.push(`/liquidity-detail?chainId=${e.chainId}&nftId=${e.nft_id}&poolAddress=${e.address}`);
    };
    const [searchBy, setSearchBy] = useState(['Owner', 'owner']);
    const [searchValue, setSearchValue] = useState<string>('');

    const trStyle = {
        className: { i_text_copy },
        fontSize: '12px',
        color: colorTheme('gray.400', 'gray.0'),
    } as unknown as BoxProps;

    const FavBt = (favInfo: any) => {
        return (
            <FavButton
                w="20px"
                h="20px"
                variant="base"
                isFav={favInfoKey(favInfo) in favorite.favLiquidity}
                setIsFav={(isAdd: boolean) =>
                    dispatch.favorite.modFavLiquidity({
                        isAdd,
                        favInfo,
                    })
                }
                cursor="pointer"
                boxSize="15px"
                m="0!important"
                ml={{ base: 'auto !important', sm: '0px !important' }}
            />
        );
    };
    const tab = [
        ['NFT Id', '60px'],
        ['Pool/fees', '226px'],
        ['Owner', '163px'],
        ['Price Range', '400px'],
    ];
    return (
        <Card variant="base" w="100%" alignItems="left" p="30px 20px 30px 20px" {...rest}>
            <VStack spacing="14px" alignItems="left">
                {showSearch && (
                    <Flex w="100%" direction={{ base: 'column', sm: 'row' }} alignItems={{ base: 'center', sm: 'start' }}>
                        <Menu>
                            {() => (
                                <>
                                    <MenuButton
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        className={i_text_copy_bold}
                                        fontSize="14px"
                                        w={{ base: '100%', sm: '178px' }}
                                        h="40px"
                                        bgColor={colorTheme('tertiary.50', '#272C3C')}
                                    >
                                        {searchBy[0]}
                                    </MenuButton>
                                    <MenuList minW="178px" className={i_text_copy}>
                                        <MenuItem
                                            onClick={() => {
                                                setSearchBy(['Owner', 'owner']);
                                            }}
                                        >
                                            Owner
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setSearchBy(['ID', 'nft_id']);
                                            }}
                                        >
                                            ID
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setSearchBy(['Pool', 'pool_addr']);
                                            }}
                                        >
                                            Pool
                                        </MenuItem>
                                    </MenuList>
                                </>
                            )}
                        </Menu>
                        <InputGroup
                            w={{ base: '100%', sm: '380px' }}
                            h="41px"
                            ml={{ base: '0px', sm: '15px' }}
                            mt={{ base: '20px', sm: '0px' }}
                        >
                            <Input
                                h="100%"
                                variant="filled"
                                placeholder={'Search by ' + searchBy[1]}
                                size="lg"
                                className={i_text_copy_bold}
                                bgColor={colorTheme('tertiary.50', '#272C3C')}
                                fontSize="12px"
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </InputGroup>
                        <CustomButton
                            mt={{ base: '20px', sm: '8px' }}
                            variant="primary"
                            w="129px"
                            h="34px"
                            className={i_text_copy}
                            ml={{ base: '0px', sm: 'auto' }}
                            text="Search"
                            onClick={() => setSearchParam?.({ [searchBy[1]]: searchValue })}
                        ></CustomButton>
                    </Flex>
                )}
                {!isMobile && (
                    <HStack spacing="20px">
                        <Text {...trStyle} color={colorTheme('primary.300', 'gray.0')} w="30px" fontSize="12px" pl="10px">
                            #
                        </Text>

                        {tab.map((e: any, i: any) => {
                            return (
                                <Text key={i} w={e[1]} {...trStyle}>
                                    {e[0]}
                                </Text>
                            );
                        })}
                    </HStack>
                )}
                <Divider />

                {pageData.data.map((item, i) => {
                    const favInfo = {
                        chainId: item.nft_id,
                        address: item.address,
                    };
                    return (
                        <Stack
                            key={i}
                            direction={{ base: 'column', sm: 'row' }}
                            alignItems={{ base: 'start', sm: 'center' }}
                            spacing="20px"
                        >
                            <Text
                                {...trStyle}
                                color={colorTheme('primary.300', 'gray.0')}
                                w="30px"
                                fontSize="16px"
                                pl={{ base: '0px', sm: '10px' }}
                            >
                                {i + 1}
                            </Text>
                            <HStack
                                w={{ base: 'unset', sm: '60px' }}
                                mt="2px !important"
                                cursor="pointer"
                                onClick={() => jumpLiquidity(item)}
                            >
                                {isMobile && <Text {...trStyle}>{tab[0][0]}</Text>}
                                <Text className={i_text_copy_bold} fontSize="13px">
                                    {item.nft_id}
                                </Text>
                            </HStack>

                            <HStack
                                w={{ base: '100%', sm: '226px' }}
                                mt={{ base: '20px !important', sm: '2px !important' }}
                                cursor="pointer"
                                onClick={() => jumpPool(item)}
                            >
                                <TokenIcons
                                    tokenAddrA={item.tokenX_address}
                                    tokenAddrB={item.tokenY_address}
                                    initialToggle={false}
                                    chainId={item.chainId}
                                    showChainIcon={true}
                                />
                                <FeeRate
                                    tokenA={item.tokenX as TokenSymbol}
                                    tokenB={item.tokenY as TokenSymbol}
                                    feeTier={item.fee}
                                    initialToggle={false}
                                />
                                {isMobile && FavBt(favInfo)}
                            </HStack>

                            <HStack w="163px">
                                {isMobile && <Text {...trStyle}>{tab[2][0]}</Text>}
                                <Text className={i_text_copy} fontSize="13px">
                                    {truncateWalletAddress(item.owner, 6, 3)}
                                </Text>
                            </HStack>

                            {/* <HStack w="182px">
                                <Text className={i_text_copy} fontSize="13px">
                                    {formatNumber(item.liquidity)}
                                </Text>
                            </HStack> */}
                            <Flex direction="column">
                                {isMobile && <Text {...trStyle}>{tab[3][0]}</Text>}
                                <TokenSwapPriceRange
                                    tokenAddrA={item.tokenX_address}
                                    tokenAddrB={item.tokenY_address}
                                    minPriceBPerA={item.priceLeft}
                                    maxPriceBPerA={item.priceRight}
                                    chainId={item.chainId}
                                    tokenSymbolX={item.tokenX}
                                    tokenSymbolY={item.tokenY}
                                    w={{ base: 'unset', sm: '400px' }}
                                />
                            </Flex>
                            {!isMobile && FavBt(favInfo)}
                            {isMobile && <Divider />}
                        </Stack>
                    );
                })}

                <Pagination
                    totalPage={pageData.totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="50px !important"
                />
            </VStack>
        </Card>
    );
};
