import { BoxProps, Text, useColorMode, Box } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../utils/funcs';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Card from '../../components/Card/Card';
import { useEffect, useState } from 'react';
import { formatNumber } from '../../utils/tokenMath';
import { i_h2, i_text_copy } from '../../style';
import { DataSeries } from './types';

type FeesChartProps = {
    height?: string;
    width?: string;
    variant?: string;
    series: DataSeries;
} & BoxProps;

export const FeesChart: React.FC<FeesChartProps> = (props) => {
    const { height, series, variant, width, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [value, setValue] = useState([0, 0]);

    useEffect(() => {
        const seriesMaxIdx = series[0].data.length - 1;
        if (series && seriesMaxIdx >= 0) {
            setValue(series[0].data[series[0].data.length - 1]);
        }
    }, [series]);

    const options: ApexOptions = {
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        yaxis: {
            show: false,
            title: {
                text: 'Fees',
                style: {
                    color: colorTheme('#3A2F53', '#9B8FAE'),
                },
            },
            labels: {
                style: {
                    colors: colorTheme('#3A2F53', '#9B8FAE'),
                },
            },
        },
        colors: [colorTheme('#6A20B3', '#8B62FF')],
        xaxis: {
            type: 'datetime',
            axisBorder: {
                show: false,
                color: colorTheme('#52466A', '#8F84A0'),
            },
            labels: {
                style: {
                    colors: colorTheme('#3A2F53', '#9B8FAE'),
                },
                datetimeUTC: false,
            },
        },
        tooltip: {
            x: {
                show: false,
                format: 'dd MMM yyyy',
            },
            theme: colorTheme('light', 'dark'),
            custom: () => {
                return '';
            },
        },
        grid: {
            show: false,
            borderColor: colorTheme('#B7B1BE', '#52466A'),
        },
        chart: {
            toolbar: {
                show: false,
            },
            events: {
                mouseMove: (e, d, c) => {
                    const series = c.config.series;
                    const seriesMaxIdx = series[0].data.length - 1;
                    if (seriesMaxIdx === -1) {
                        setValue([0, 0]);
                    } else if (c.dataPointIndex === -1) {
                        setValue(series[0].data[seriesMaxIdx]);
                    } else {
                        setValue(series[0].data[Math.min(c.dataPointIndex, seriesMaxIdx)]);
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
    };

    return (
        <Card variant={variant ?? 'base'} w="100%" position="relative" {...rest}>
            <Text position="absolute" left="16px" top="10px" className={i_text_copy} fontSize="16px" color="gray.400">
                Fees
            </Text>

            <Text
                position="absolute"
                left="16px"
                top="30px"
                className={i_h2}
                fontSize={{ base: '24px', sm: '32px' }}
                color={colorTheme('gray.800', 'gray.0')}
            >
                ${formatNumber(value[1], 2, 2, 1)}
            </Text>

            <Box w="100%" mt="14px !important">
                <ReactApexChart options={options} series={series} height={height ?? '300px'} width={width ?? '100%'} type="bar" />
            </Box>
        </Card>
    );
};
