import { Text, Flex, Tooltip, IconButton, BoxProps, Select, Stack, HStack } from '@chakra-ui/react';
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { i_text_copy } from '../../style';
import useIsMobile from '../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

type PaginationProps = {
    totalPage: any;
    currentPage: any;
    pageSize: any;
    setCurrentPage: (v: any) => void;
    setPageSize: (v: any) => void;
} & BoxProps;

export const Pagination: React.FC<PaginationProps> = (props) => {
    const { totalPage, currentPage, pageSize, setCurrentPage, setPageSize, ...rest } = props;
    const isMobile = useIsMobile();

    return isMobile ? (
        <>
            <Flex
                px={{ base: '0px', sm: '20px' }}
                m={4}
                alignItems={{ base: 'start', sm: 'center' }}
                justifyContent="space-between"
                {...rest}
            >
                <Stack>
                    <Text className={i_text_copy} color="gray.400">
                        Rows per page
                    </Text>
                    <Select
                        w={'100px'}
                        value={pageSize}
                        className={i_text_copy}
                        fontSize="12px"
                        size="sm"
                        borderRadius="3px"
                        onChange={(v) => {
                            setPageSize(v.target.value);
                            //setCurrentPage(1);
                        }}
                    >
                        {[5, 10, 30, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </Select>
                </Stack>
                <Stack alignItems="center">
                    <Text className={i_text_copy} color="gray.400">
                        Page{' '}
                        <Text fontWeight="bold" as="span">
                            {currentPage}
                        </Text>{' '}
                        of{' '}
                        <Text fontWeight="bold" as="span">
                            {totalPage}
                        </Text>
                    </Text>
                    <HStack spacing="20px" mt="12px !important">
                        <HStack spacing="0px">
                            <Tooltip label="First Page">
                                <IconButton
                                    aria-label="explore"
                                    onClick={() => {
                                        setCurrentPage(1);
                                    }}
                                    isDisabled={currentPage === 1}
                                    size="xs"
                                    borderRadius="3px"
                                    icon={<ArrowLeftIcon h={2} w={2} />}
                                    mr={4}
                                />
                            </Tooltip>
                            <Tooltip label="Previous Page">
                                <IconButton
                                    aria-label="explore"
                                    onClick={() => {
                                        setCurrentPage(currentPage - 1);
                                    }}
                                    isDisabled={currentPage === 1}
                                    size="xs"
                                    borderRadius="3px"
                                    icon={<ChevronLeftIcon h={4} w={4} />}
                                />
                            </Tooltip>
                        </HStack>
                        <HStack>
                            <Tooltip label="Next Page">
                                <IconButton
                                    aria-label="explore"
                                    onClick={() => {
                                        setCurrentPage(currentPage + 1);
                                    }}
                                    isDisabled={currentPage === totalPage}
                                    size="xs"
                                    borderRadius="3px"
                                    icon={<ChevronRightIcon h={4} w={4} />}
                                />
                            </Tooltip>

                            <Tooltip label="Last Page">
                                <IconButton
                                    aria-label="explore"
                                    onClick={() => {
                                        setCurrentPage(totalPage);
                                    }}
                                    isDisabled={currentPage === totalPage}
                                    size="xs"
                                    borderRadius="3px"
                                    icon={<ArrowRightIcon h={2} w={2} />}
                                    ml={4}
                                />
                            </Tooltip>
                        </HStack>
                    </HStack>
                </Stack>
            </Flex>
        </>
    ) : (
        <>
            <Flex
                direction={{ base: 'column', sm: 'row' }}
                px={{ base: '0px', sm: '20px' }}
                justifyContent="space-between"
                m={4}
                alignItems={{ base: 'start', sm: 'center' }}
                {...rest}
            >
                <Flex>
                    <Select
                        w={32}
                        value={pageSize}
                        className={i_text_copy}
                        fontSize="12px"
                        size="sm"
                        borderRadius="3px"
                        onChange={(v) => {
                            setPageSize(v.target.value);
                            //setCurrentPage(1);
                        }}
                    >
                        {[5, 10, 30, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </Select>
                </Flex>
                <Flex alignItems="center" mt={{ base: '15px', sm: '0px' }}>
                    <Tooltip label="First Page">
                        <IconButton
                            aria-label="explore"
                            onClick={() => {
                                setCurrentPage(1);
                            }}
                            isDisabled={currentPage === 1}
                            size="xs"
                            borderRadius="3px"
                            icon={<ArrowLeftIcon h={2} w={2} />}
                            mr={4}
                        />
                    </Tooltip>
                    <Tooltip label="Previous Page">
                        <IconButton
                            aria-label="explore"
                            onClick={() => {
                                setCurrentPage(currentPage - 1);
                            }}
                            isDisabled={currentPage === 1}
                            size="xs"
                            borderRadius="3px"
                            icon={<ChevronLeftIcon h={4} w={4} />}
                        />
                    </Tooltip>

                    <Text className={i_text_copy} flexShrink={0} mx={8}>
                        Page{' '}
                        <Text fontWeight="bold" as="span">
                            {currentPage}
                        </Text>{' '}
                        of{' '}
                        <Text fontWeight="bold" as="span">
                            {totalPage}
                        </Text>
                    </Text>

                    <Tooltip label="Next Page">
                        <IconButton
                            aria-label="explore"
                            onClick={() => {
                                setCurrentPage(currentPage + 1);
                            }}
                            isDisabled={currentPage === totalPage}
                            size="xs"
                            borderRadius="3px"
                            icon={<ChevronRightIcon h={4} w={4} />}
                        />
                    </Tooltip>

                    <Tooltip label="Last Page">
                        <IconButton
                            aria-label="explore"
                            onClick={() => {
                                setCurrentPage(totalPage);
                            }}
                            isDisabled={currentPage === totalPage}
                            size="xs"
                            borderRadius="3px"
                            icon={<ArrowRightIcon h={2} w={2} />}
                            ml={4}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
        </>
    );
};
