import { Models } from '@rematch/core';
import { block } from './block/block';
import { token } from './token/token';
import { announceBar } from './announceBar/announceBar';
import { dashboard } from './dashboard/dashboard';
import { favorite } from './favorite/favorite';
import { transaction } from './transaction/transaction';

export interface RootModel extends Models<RootModel> {
    block: typeof block;
    token: typeof token;
    announceBar: typeof announceBar;
    dashboard: typeof dashboard;
    favorite: typeof favorite;
    transaction: typeof transaction;
}

export const models: RootModel = {
    block,
    token,
    announceBar,
    dashboard,
    favorite,
    transaction,
};