import { Flex, VStack, Text, useColorMode, Input, InputGroup, Image, Icon, InputRightElement, Stack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/Buttons/CustomButton/CustomButton';
import Card from '../../../components/Card/Card';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { SearchKeys } from '../../../state/models/transaction/transaction';
import { RootDispatch, RootState } from '../../../state/store';
import { i_h5, i_text_copy, i_text_copy_bold } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { CgCalendar } from 'react-icons/cg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

export const SearchBlock: React.FC = (pros) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [showMore, setShowMore] = useState(false);
    const { transaction } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));
    const currentTab = transaction.currentTab;
    const [isRequire, setIsRequire] = useState(false);

    const onStartDateChange = useCallback(
        (e: any) => {
            const searchKeys = { ...transaction.control.searchKeys };
            searchKeys.timeStart = e;
            dispatch.transaction.setSearchKey(searchKeys);
        },
        [transaction, dispatch]
    );

    const onEndDateChange = useCallback(
        (e: any) => {
            const searchKeys = { ...transaction.control.searchKeys };
            searchKeys.timeEnd = e;
            dispatch.transaction.setSearchKey(searchKeys);
        },
        [transaction, dispatch]
    );

    const onSearchKeyChange = useCallback(
        (e: any, key: any) => {
            const searchKeys = { ...transaction.control.searchKeys };
            searchKeys[key as keyof SearchKeys] = e.target.value;
            dispatch.transaction.setSearchKey(searchKeys);
        },
        [transaction, dispatch]
    );

    useEffect(() => {
        if (currentTab === 'Stat') {
            setShowMore(true);
            setIsRequire(true);
        } else {
            setIsRequire(false);
        }
    }, [transaction.currentTab]);

    return (
        <VStack w={{ base: '100%', sm: '1150px' }} alignItems="left" mt={{ base: '20px !important', sm: '60px !important' }} spacing="20px">
            <Text className={i_text_copy} fontSize="14px" color={colorTheme('gray.500', 'gray.0')}>
                Search Transaction
            </Text>
            <Card
                w="100%"
                px={{ base: '24px', sm: '24px' }}
                py="24px"
                transitionProperty="all"
                transitionDuration="0.3s"
                transitionTimingFunction="ease"
                transitionDelay="0.2s"
            >
                <Stack alignItems={{ base: 'start', sm: 'center' }} direction={{ base: 'column', sm: 'row' }}>
                    <Flex alignItems="center">
                        <Text w="75px" className={i_h5}>
                            Address
                        </Text>

                        <InputGroup w={{ base: '100%', sm: '700px' }} h="46px" ml={{ base: '20px', sm: '45px' }}>
                            <Input
                                h="100%"
                                variant="filled"
                                placeholder={isRequire ? 'Search Account Address (Required without toContract)' : 'Search Account Address'}
                                size="lg"
                                className={i_text_copy_bold}
                                bgColor={colorTheme('gray.0', '#272C3C')}
                                fontSize="12px"
                                onChange={(e: any) => {
                                    onSearchKeyChange(e, 'address');
                                }}
                                value={transaction.control.searchKeys.address}
                            />
                        </InputGroup>
                    </Flex>

                    <Flex alignItems="center" justifyContent="center" mt={{ base: '20px !important', sm: '0px !important' }}>
                        <CustomButton
                            variant="primary"
                            w="129px"
                            h="34px"
                            className={i_text_copy}
                            text="Search"
                            onClick={() => {
                                dispatch.transaction.setSearchKey({ ...transaction.control.searchKeys });
                            }}
                        />
                        <Flex
                            alignItems="center"
                            ml="33px"
                            cursor="pointer"
                            onClick={() => {
                                showMore ? setShowMore(false) : setShowMore(true);
                            }}
                        >
                            <Text className={i_text_copy_bold} color="#001AFF">
                                {showMore ? 'Less' : 'More'}
                            </Text>
                            <Image
                                src={
                                    showMore
                                        ? process.env.PUBLIC_URL + '/assets/Transaction/upperArrow.svg'
                                        : process.env.PUBLIC_URL + '/assets/Transaction/downArrow.svg'
                                }
                                ml="7px"
                            ></Image>
                        </Flex>
                    </Flex>
                </Stack>

                {showMore && (
                    <>
                        <Flex
                            direction={{ base: 'column', sm: 'row' }}
                            alignItems={{ base: 'start', sm: 'center' }}
                            mt={{ base: '20px', sm: '0px' }}
                        >
                            <Flex w={{ base: '100%', sm: 'unset' }} alignItems="center">
                                <Text w={{ base: '40px', sm: '75px' }} className={i_h5}>
                                    Pool
                                </Text>
                                <InputGroup w={{ base: '210px', sm: '361px' }} h="46px" ml={{ base: 'auto', sm: '45px' }}>
                                    <Input
                                        h="100%"
                                        variant="filled"
                                        placeholder={isRequire ? 'Search Pool Address' : 'Search Pool Address'}
                                        size="lg"
                                        className={i_text_copy_bold}
                                        bgColor={colorTheme('gray.0', '#272C3C')}
                                        fontSize="12px"
                                        onChange={(e: any) => {
                                            onSearchKeyChange(e, 'pool');
                                        }}
                                        value={transaction.control.searchKeys.pool}
                                    />
                                </InputGroup>
                            </Flex>
                            <Flex alignItems="center" mt={{ base: '20px', sm: '0px' }}>
                                <Text w="75px" className={i_h5} ml={{ base: '0px', sm: '72px' }}>
                                    Token
                                </Text>
                                <InputGroup w={{ base: '210px', sm: '361px' }} h="46px" ml={{ base: 'auto', sm: '30px' }}>
                                    <Input
                                        h="100%"
                                        variant="filled"
                                        placeholder="Search Token Address"
                                        size="lg"
                                        className={i_text_copy_bold}
                                        bgColor={colorTheme('gray.0', '#272C3C')}
                                        fontSize="12px"
                                        onChange={(e: any) => {
                                            onSearchKeyChange(e, 'token');
                                        }}
                                        value={transaction.control.searchKeys.token}
                                    />
                                </InputGroup>
                            </Flex>
                        </Flex>
                        <Flex direction={{ base: 'column', sm: 'row' }} alignItems="center" mt={{ base: '20px', sm: '0px' }}>
                            <Flex alignItems="center">
                                <Text w="75px" className={i_h5}>
                                    Time Start
                                </Text>
                                <InputGroup w={{ base: '210px', sm: '361px' }} h="46px" ml={{ base: 'auto', sm: '45px' }} zIndex="2">
                                    <DatePicker
                                        placeholderText={isRequire ? 'Search Time (Required)' : 'Search Time'}
                                        selected={transaction.control.searchKeys.timeStart}
                                        onChange={(date: any) => {
                                            onStartDateChange(date);
                                        }}
                                        className={colorTheme('light-picker', 'dark-picker')}
                                    />

                                    <InputRightElement children={<Icon as={CgCalendar} boxSize="24px" />} />
                                </InputGroup>
                            </Flex>
                            <Flex alignItems="center" mt={{ base: '20px', sm: '0px' }}>
                                <Text w="75px" className={i_h5} ml={{ base: '0px', sm: '72px' }}>
                                    Time End
                                </Text>
                                <InputGroup w={{ base: '210px', sm: '361px' }} h="46px" ml={{ base: 'auto', sm: '30px' }} zIndex="2">
                                    <DatePicker
                                        placeholderText={isRequire ? 'Search Time (Required)' : 'Search Time'}
                                        selected={transaction.control.searchKeys.timeEnd}
                                        onChange={(date: any) => {
                                            onEndDateChange(date);
                                        }}
                                        className={colorTheme('light-picker', 'dark-picker')}
                                    />
                                    <InputRightElement children={<Icon as={CgCalendar} boxSize="24px" />} />
                                </InputGroup>
                            </Flex>
                        </Flex>
                        <Flex direction="column">
                            <Flex alignItems="center">
                                <Text w="100px" className={i_h5}>
                                    ToContract
                                </Text>

                                <InputGroup w={{ base: '100%', sm: '400px' }} h="46px" ml={{ base: '20px', sm: '20px' }}>
                                    <Input
                                        h="100%"
                                        variant="filled"
                                        placeholder={
                                            isRequire ? 'Search Account Address (Required without address)' : 'Search Account Address'
                                        }
                                        size="lg"
                                        className={i_text_copy_bold}
                                        bgColor={colorTheme('gray.0', '#272C3C')}
                                        fontSize="12px"
                                        onChange={(e: any) => {
                                            onSearchKeyChange(e, 'withContract');
                                        }}
                                        value={transaction.control.searchKeys.withContract}
                                    />
                                </InputGroup>
                            </Flex>
                        </Flex>
                    </>
                )}
            </Card>
        </VStack>
    );
};
